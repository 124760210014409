<template>
  <div>
    <div class="modal fade" id="momoModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content py-0">
          <!-- <a href="#" class="modal-close" data-dismiss="modal" aria-label="Close"><em class="ti ti-close"></em></a> -->
          <div class="popup-body p-4">
            <div
              class="modal-header d-flex justify-content-between align-items-center"
            >
              <div class="momo-img">
                <img src="@/assets/images/momo-payment-2.png" class="w-100" />
              </div>
              <div class="mo-user-details">
                <h6>
                  {{ user.country.currencySymbol }} {{ momo.orderTotalPrice }}
                </h6>
                <h6>{{ user.email }}</h6>
              </div>
            </div>
            <div class="form mt-2">
              <form @submit.prevent="payNow()">
                <div class="momo-form-group">
                  <label for=""
                    >Amount ({{ user.country.currencySymbol }})</label
                  >
                  <div class="input-wrapper">
                    <input
                      type="text"
                      :value="user.country.currencySymbol"
                      disabled
                      class="form-control ipt-sm"
                    />
                    <input
                      type="text"
                      :value="momo.orderTotalPrice"
                      disabled
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="momo-form-group">
                  <label for="">Phone Number</label>
                  <div class="input-wrapper">
                    <input
                      type="text"
                      :value="user.country.countryCode"
                      disabled
                      class="form-control ipt-sm"
                    />
                    <input
                      type="text"
                      :value="user.phoneNumber"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="momo-form-group">
                  <label for="">Add a note (optional)</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    class="form-control"
                  >
                                        Write a short note
                                    </textarea
                  >
                </div>
                <div class="momo-submit my-4">
                  <button type="submit" class="btn btn-primary w-100">
                    Pay Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>

    <div class="modal fade" id="momoModalPending">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content py-0">
          <div class="popup-body p-4">
            <div
              class="modal-header d-flex justify-content-between align-items-center"
            >
              <div class="momo-img">
                <img src="@/assets/images/momo-payment-2.png" class="w-100" />
              </div>
              <div class="mo-user-details">
                <h6>
                  {{ user.country.currencySymbol }}{{ momo.orderTotalPrice }}
                </h6>
                <h6>{{ user.email }}</h6>
              </div>
            </div>
            <div class="row-block-display">
              <h6 class="text-tile text-center font-12 mb-4">
                We are confirming your payment. This could take a couple of
                minutes
              </h6>
              <div class="pending-confirmation">
                <!-- <div class="rotate"></div> -->
                <img
                  src="@/assets/images/rotate.png"
                  class="rotate"
                  width="100"
                  height="100"
                  alt=""
                />
              </div>
              <div class="payment-confirmation-block mb-4">
                <div class="payment-block payment-success mb-4">
                  <span class="text">Payment Made</span>
                  <span class="loader-done">
                    <img src="@/assets/images/group-5.svg" alt="" />
                  </span>
                </div>
                <div class="payment-block payment-pending mb-4">
                  <span class="text">Payment Confirmed</span>
                  <span class="loader-done">
                    <img
                      src="@/assets/images/89.gif"
                      width="25"
                      height="26"
                      alt=""
                    />
                  </span>
                </div>
              </div>
              <div class="text-tile-link">
                <a href="#" class="text-center text-tile mb-5">
                  Need help with the transactions?
                </a>
              </div>
              <div class="payment-lined-text">
                <img src="@/assets/images/Lock.svg" alt="" />
                <span>SECURED BY MOMO AGENT</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  props: {
    momo: {},
  },
  computed: {
    ...mapState({
      user: (context) => context.user,
    }),
  },
  data() {
    return {
      phone: "",
    };
  },
  methods: {
    payNow() {
      this.loading = true;
      const details = {
        // amount:this.checkout.orderTotalPrice,
        amount: "100",
        currency: "EUR",
        // externalId:this.checkout.referenceId,
        externalId: "12222222",
        payer: {
          partyIdType: "MSISDN",
          partyId: "46733123454",
          // partyId: this.phone
        },
        // payerMessage: this.note,
        payerMessage: "payer note",
        payeeNote: "",
        // currency:this.user.country.currency,
      };
      axios
        .post(
          "https://sandbox.momodeveloper.mtn.com/collection/v1_0/requesttopay",
          details,
          {
            headers: {
              "content-type": "application/json",
              Authorization:
                "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSMjU2In0.eyJjbGllbnRJZCI6ImFlNzJkMjhmLWVlZWQtNDNlYy1iMzNkLWFkOWM2ZTEwMTdmMiIsImV4cGlyZXMiOiIyMDIyLTAzLTI0VDE0OjM3OjMzLjY5OCIsInNlc3Npb25JZCI6IjRkNDU4ZjM5LTM4MTQtNGM5Zi1hYTIyLTFiZTc4M2ZlNjY5YiJ9.X7EEm2xpCRL16phKeIo0qyPEBrZnDirzo_0ucmtxRVhAShJX7yNBvr32w6fkuZ0xtG4ElcmzX38_7zBjc8nfqGlSN11PNqPVcszjQC_KSC8koelFlazRkn9wMsDXQPxgCCEZ9bqsPrZv2Kcrgu7zQkQUVaTM1ay4vOyu0w6Ye_hS2kxy72zRiifnGJ2nbi0iF8nvM4HnFfIhzDix5RUy6x_HgH9C5UdI4ZDCtRMjvATKivOVlt_TDkrGRlEMN7W12EP-iQhRvQIIsrVIq2aU-6s_FT9lo7DtGu_jtDEUaR_UlOP6AKwRPWO4Zd4fkwFPUpSazH9jqnhwVBmr78PNwQ",
              // "X-Reference-Id": this.checkout.referenceId,
              "X-Reference-Id": "f3ad47ce-a29f-403d-b84f-52c5ac2cd826",
              "X-Target-Environment": "sandbox",
              "Ocp-Apim-Subscription-Key": "ffde1a02903949cc83ff39ec2e14eab8",
            },
          }
        )
        .then((resp) => {
          this.loading = false;
          console.log(resp);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  created() {
    this.phone = this.user.phoneNumber;
    // console.log(this.user);
  },
};
</script>

<style scoped>
.rotate {
  color: white;
  animation: mymove 3s infinite;
  margin-bottom: 25px;
}

@keyframes mymove {
  100% {
    transform: rotate(360deg);
  }
}
.momo-form-group {
  margin-top: 25px;
}
.momo-form-group label {
  font-size: 14px;
}
.momo-form-group input {
  height: 45px;
  font-size: 14px;
}
.momo-form-group input::-webkit-input-placeholder {
  font-size: 14px;
}
.input-wrapper {
  display: flex;
}
.ipt-sm {
  width: 60px;
  text-align: center;
  margin-right: 10px;
}
.mo-user-details h6 {
  font-size: 12px;
  font-weight: bold;
}
.momo-img {
  width: 40px;
}
.pending-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-block-display {
  border-top: 1px solid rgba(0, 4, 45, 0.1);
  padding-top: 16px;
}
.row-block-display .text-tile {
  font-weight: 300;
  width: 75%;
  margin: 0 auto;
  color: #061435;
}
.payment-block {
  border: 1px solid rgba(6, 20, 53, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  margin-bottom: 14px;
}

.text-tile-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-tile-link a {
  text-decoration: underline;
}
.payment-lined-text {
  border: 1px solid rgba(6, 20, 53, 0.2);
  border-radius: 4px;
  padding: 10px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 70%;
}
.payment-lined-text span {
  color: #846100;
  font-size: 10px;
  font-weight: bolder;
}
</style>
