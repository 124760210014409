<template>
  <div>
    <div class="topbar-wrap move-rgt" id="topbar-wrap">
      <div class="topbar is-sticky">
        <div class="container">
          <div class="d-flex nav-split align-items-center">
            <ul class="topbar-nav d-lg-none" @click="toggleSidebar()">
              <li class="topbar-nav-item relative">
                <a class="toggle-nav active" href="#" @click="toggleNav()">
                  <div class="toggle-icon">
                    <span class="toggle-line"></span>
                    <span class="toggle-line"></span>
                    <span class="toggle-line"></span>
                    <span class="toggle-line"></span>
                  </div>
                </a>
              </li>
            </ul>
            <ul class="topbar-nav">
              <li class="topbar-nav-item relative mr-0">
                <language />
              </li>
              <li class="topbar-nav-item relative">
                <!-- <span class="user-welcome d-none d-lg-inline-block"> {{$t('welcome')}} {{user.firstName}}</span> -->
                <div class="ct-no">
                  <router-link to="checkout">{{
                    cart.products.length
                  }}</router-link>
                </div>
                <router-link class="toggle-tigger user-thumb" to="checkout">
                  <div class="ct-icon">
                    <em class="ti ti-shopping-cart em-font"></em>
                  </div>
                </router-link>
              </li>
              <li
                class="topbar-nav-item relative mx-0"
                v-if="cart.bundleOffers.length > 0"
              >
                <!-- <span class="user-welcome d-none d-lg-inline-block"> {{$t('welcome')}} {{user.firstName}}</span> -->
                <div class="ct-no">
                  <router-link to="bundle-checkout">{{
                    cart.bundleOffers.length
                  }}</router-link>
                </div>
                <router-link
                  class="toggle-tigger user-thumb"
                  to="bundle-checkout"
                >
                  <div class="ct-icon">
                    <em class="fas fa-gift em-font"></em>
                  </div>
                </router-link>
              </li>
              <li class="side-line py-1">
                <a class="toggle-tigger" href="#" @click="toggleDropdown()">
                  <em class="ti ti-user d-lg-none em-font"></em>
                  <div class="d-lg-flex d-none align-items-center">
                    <div class="sl-lft mr-3">
                      <h6 class="mb-0">
                        {{ user.firstName }} {{ user.lastName }}
                      </h6>
                      <span>{{ user.email }}</span>
                    </div>
                    <i class="fa fa-chevron-down"></i>
                  </div>
                </a>
                <div
                  :class="active ? 'active' : ''"
                  class="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown"
                >
                  <div class="user-status">
                    <div class="user-status-balance">
                      <small>{{ user.outletName }}</small>
                    </div>
                  </div>
                  <ul class="user-links">
                    <li>
                      <router-link to="orders"
                        ><i class="ti ti-infinite"></i>
                        {{ $t("orders") }}</router-link
                      >
                    </li>
                  </ul>
                  <ul class="user-links" v-if="user.outlets.length > 1">
                    <li>
                      <router-link to="/outlets"
                        ><i class="ti ti-home"></i>
                        {{ $t("switch_outlet") }}</router-link
                      >
                    </li>
                  </ul>
                  <ul class="user-links bg-light">
                    <li>
                      <a href="#" @click="logout()"
                        ><i class="ti ti-power-off"></i> {{ $t("logout") }}</a
                      >
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="navbar navbar-mobile active" id="navbar">
        <div class="logo-container">
          <router-link class="topbar-logo" to="/dashboard">
            <img src="../../../assets/images/logo.png" alt="logo" />
          </router-link>
        </div>
        <div class="container">
          <div class="navbar-innr">
            <ul class="navbar-menu w-100">
              <li
                @click="closeNav()"
                :class="this.$route.name == 'dashboard' ? 'actived' : ''"
              >
                <router-link to="/dashboard"
                  ><em class="ikon ikon-dashboard"></em> {{ $t("dashboard") }}
                </router-link>
              </li>
              <li
                @click="closeNav()"
                :class="this.$route.name == 'products' ? 'actived' : ''"
              >
                <router-link to="/store"
                  ><em class="ikon ikon-exchange"></em>
                  {{ $t("store") }}</router-link
                >
              </li>
              <li
                @click="closeNav()"
                :class="this.$route.name == 'orders' ? 'actived' : ''"
              >
                <router-link to="/orders"
                  ><em class="ikon ikon-distribution"></em>
                  {{ $t("orders") }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <router-view></router-view>
    <div class="footer-bar bg-white pt-3">
      <!-- <div class="text-center">
                <div> {{$t('f_txt1')}}</div>
            </div> -->
      <div class="w-100 text-center">
        <img
          v-if="user.country.tenantId == 11"
          src="../../../assets/images/fimg2.jpeg"
          style="width: 80%"
          alt=""
        />
        <img
          v-else
          src="../../../assets/images/fimg3.png"
          style="width: 300px"
          alt=""
        />
      </div>
      <div class="text-center">
        <div class="copyright-text">
          &copy; {{ new Date().getFullYear() }} {{ $t("f_c") }}
        </div>
        <!-- <language /> -->
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState({
        user: (context) => context.user,
        token: (context) => context.user,
        cart: (context) => context.cart,
      }),
    },
    data() {
      return {
        active: false,
      };
    },
    methods: {
      logout() {
        this.$store.commit("logout");
      },
      closeNav() {
        this.toggleSidebar();
        this.toggleNav();
      },
      toggleSidebar(open = true) {
        if (!open) return false;

        var dm = document.getElementById("navbar");
        dm.classList.add("navbar-mobile");
        var list = dm.classList;
        list.forEach((name) => {
          if (name == "active") {
            dm.classList.remove(name);
          } else {
            dm.classList.add("active");
          }
        });
      },
      toggleNav() {
        window.$("#navTog").toggleClass("navactive");
        window.$(".toggle-nav").toggleClass("active");
        window.$(".topbar-wrap").toggleClass("move-left");
        window.$(".topbar-wrap").toggleClass("move-rgt");
      },
      toggleDropdown() {
        this.active = !this.active;
      },
    },
    created() {
      this.$store.commit("getUser");
      // console.log(this.user)
      if (!this.user) {
        this.$store.commit("logout");
      }
    },
  };
</script>

<style scoped>
  .navbar-menu li.actived {
    background: rgba(17, 42, 99, 0.1);
    border-color: transparent !important;
    border-radius: 7px;
  }
  .navbar-menu li.actived a {
    border-color: transparent !important;
    font-weight: bold;
  }
  .navbar-menu li {
    margin-bottom: 10px;
  }
  .navbar-menu li a {
    font-size: 15px;
    padding-left: 57px;
    color: rgba(17, 42, 99, 0.5);
    font-family: plus_jakarta_displayregular;
  }
  .navbar-menu li.actived a {
    color: #112a63;
  }
  .navbar-menu li a em {
    font-size: 32px;
  }
  .navbar-menu li a:hover {
    background: rgba(17, 42, 99, 0.1);
    border-radius: 7px;
  }
  .nav-split {
    justify-content: space-between;
  }
  .side-line {
    border-left: 2px solid #06143540;
    padding-left: 15px;
    margin-left: 10px;
  }
  .side-line .toggle-tigger {
    color: black;
    display: block;
  }
  .side-line .toggle-tigger span {
    color: rgba(6, 20, 53, 0.7);
  }
  .side-line .toggle-tigger h6 {
    font-weight: bold;
    line-height: 1;
  }
  .move-left {
    margin-left: 0 !important;
  }
  .topbar-wrap {
    transition: all 0.5s;
    box-shadow: 0px 2px 17px rgba(171, 171, 171, 0.1);
  }
  .logo-container {
    /* height: 178px; */
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .em-font {
    font-size: 28px;
  }
  .ct-no {
    transform: translate(35px, -14px);
    font-size: 12px;
    background: red;
    height: 17px;
    width: 17px;
    border-radius: 17px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ct-no a {
    color: #fff;
  }
  .navactive {
    left: 0;
    display: block;
    overflow-y: auto;
  }

  .toggle-nav.active .toggle-line:nth-last-of-type(4) {
    transform-origin: top left;
    transform: rotate();
    top: -1px;
  }
  .user-thumb {
    background: #fff;
    display: block;
  }
  .user-thumb:hover {
    color: black;
  }
  @media (min-width: 991px) {
    .nav-split {
      justify-content: flex-end;
    }
    .logo-container {
      height: 67px;
    }
  }
  @media (max-width: 576px) {
    .navbar-menu li a {
      font-size: 10px;
      padding-left: 17px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
</style>
