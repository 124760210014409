<template>
  <div>
    <div class="page-content" v-if="bundleProducts.length > 0">
      <div class="container">
        <div class="row mt-3">
          <div class="col-12">
            <div class="row">
              <div
                class="col-12 d-flex justify-content-between align-items-center"
              >
                <div class="product-title d-flex align-items-center mb-3">
                  <p class="font-weight-bold mb-0">Bundle Offer</p>
                  <img src="@/assets/images/4.svg" class="ml-2" alt="" />
                </div>
                <div class="view-all">
                  <router-link to="bundle-offers"
                    >View All
                    <i class="fas fa-angle-double-right ml-2"></i>
                  </router-link>
                </div>
              </div>
              <div
                class="col-md-3 col-6 bundleProducts"
                v-for="(product, index) in bundleProducts"
                :key="index"
              >
                <router-link
                  to="/bundle-offers"
                  class="content-area card products-pg"
                >
                  <div class="card-innr">
                    <div class="card-img text-center">
                      <img :src="product.productBundle.imageURL" alt="" />
                    </div>
                    <div class="mt-2 text-center">
                      <span class="prod-name">{{
                        product.productBundle.displayName
                      }}</span>
                    </div>
                    <div class="mt-1 text-center">
                      <span class="prod-price"
                        >{{ user.country.currencySymbol }}
                        {{ formatPrice(product.productBundle.unitPrice) }}</span
                      >
                    </div>
                  </div>
                </router-link>
              </div>

              <div
                class="col-12 mt-5 d-flex justify-content-between align-items-center"
              >
                <div class="product-title d-flex align-items-center mb-3">
                  <p class="font-weight-bold mb-0">Top product category</p>
                  <img src="@/assets/images/3.svg" class="ml-2" alt="" />
                </div>
                <div class="view-all">
                  <router-link to="store-products"
                    >View All
                    <i class="fas fa-angle-double-right ml-2"></i>
                  </router-link>
                </div>
              </div>
              <div
                class="col-md-3 col-6"
                v-for="product in products"
                :key="product.id"
              >
                <router-link
                  class="content-area card products-pg"
                  to="store-products"
                >
                  <div class="card-innr">
                    <div class="card-img text-center">
                      <img :src="product.product.imageURL" alt="" />
                    </div>
                    <div class="text-center mt-3">
                      <span class="prod-name">{{
                        product.product.productName
                      }}</span>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <storeProducts v-else />
    <!-- Advert section, might need it later -->
    <!-- <div class="container" v-if="user.country.tenantId == 10">
      <div class="row">
        <div class="col-sm-6">
          <div class="ad-img">
            <img src="@/assets/images/5.jpeg" class="w-100" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="ad-img">
            <img src="@/assets/images/6.jpeg" class="w-100" />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import storeProducts from "./storeProducts.vue";
  export default {
    components: { storeProducts },
    computed: {
      ...mapState({
        cart: (context) => context.cart,
        user: (context) => context.user,
      }),
    },
    data() {
      return {
        products: [],
        convert: {},
        setTrue: true,
        productPrices: [],
        bundleProducts: [],
        showOnlyProducts: true,
      };
    },
    methods: {
      formatPrice(value) {
        var hold = parseFloat(value).toFixed(2);
        return new Intl.NumberFormat("en-NG", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(hold);
      },
      getProducts() {
        this.$store
          .dispatch(
            "getProduct",
            "services/app/Products/GetAllForCustomer?MaxResultCount=4&customerId=" +
              this.user.id
          )
          .then((resp) => {
            if (resp.data.success) {
              this.products = resp.data.result.items;
            }
          });
      },
      getBundleOffers() {
        this.$store
          .dispatch(
            "get",
            "services/app/ProductBundle/GetAllForCustomer?MaxResultCount=4&customerId=" +
              this.user.id
          )
          .then((resp) => {
            this.bundleProducts = resp.data.result.items;
          });
      },
    },
    async created() {
      await this.getBundleOffers();
      this.getProducts();
    },
  };
</script>

<style scoped>
  .view-all a {
    color: black;
    font-size: 12px;
    font-weight: bold;
  }
  .product-title img {
    width: 25px;
  }
  .bundleProducts .prod-name {
    font-size: 12px;
  }
  .prod-name {
    font-size: 13px;
    color: #061435;
    font-family: plus_jakarta_displayregular;
    line-height: 16px;
    display: block;
    /* font-weight: 400; */
  }
  .cart-subtotal h5 {
    color: #061435;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .txt-color {
    color: #6e81a9;
  }
  .cart-prod-content select {
    border: none;
  }
  .cart-prod-content .input-bordered {
    width: 43px;
    border: 1px solid rgba(17, 42, 99, 0.15);
    border-radius: 0;
    padding: 6px 0;
  }
  .cart-prod-content .input-right {
    border: 1px solid rgba(17, 42, 99, 0.15);
    border-right: none;
  }
  .cart-prod-content .input-left {
    border: 1px solid rgba(17, 42, 99, 0.15);
    border-left: none;
  }
  .cart-prod-content .ic-qty {
    border: none;
  }
  .cart-prod-content .icon-input {
    padding: 10px 8px;
  }
  .cpc-price {
    font-size: 17px;
    color: black;
  }
  .cart-prod-select select {
    font-size: 15px;
  }
  .cart-prod-select:after {
    font-size: 15px;
  }
  .cart-prod-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
  }
  .cart-prod-content {
    color: #061435;
  }
  .cart-prod-content h6 {
    font-weight: bold;
  }
  .cart-prod-img {
    width: 55px;
  }
  .cart-product {
    display: flex;
    margin-top: 26px;
    border-bottom: 1px solid rgba(17, 42, 99, 0.15);
    padding-bottom: 20px;
  }
  .cart-order-header h5 {
    color: black;
  }
  .cart-order-header .fa {
    font-size: 21px;
  }
  .cart-order-header {
    border-bottom: 1px solid rgba(6, 20, 53, 0.14);
  }
  .cart-order {
    background: #fff;
  }
  .select-wrapper select {
    border: none;
    padding: 0;
  }
  .select-wrapper {
    width: 70px;
  }
  .prod-price {
    color: black;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .float-co {
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 20px;
    right: 20px;
    background: #28a745;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    height: 65px;
    width: 65px;
  }
  .float-co em {
    font-size: 19px;
  }
  .float-co span {
    display: block;
    font-size: 12px;
  }
  .ic-qty {
    border: 1px solid rgba(17, 42, 99, 0.15);
    border-radius: 4px;
    border-right: 0;
    border-left: 0;
  }
  .icon-input {
    background-color: transparent;
    color: black;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  .icon-input:hover .fa {
    color: #fff;
  }
  .icon-input:hover {
    background: #061435;
    color: #fff;
    transition: all 0.5s;
  }
  .input-right {
    border-left: 1px solid rgba(17, 42, 99, 0.15);
    border-right: 1px solid rgba(17, 42, 99, 0.15);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .input-left {
    border-right: 1px solid rgba(17, 42, 99, 0.15);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid rgba(17, 42, 99, 0.15);
  }
  .card {
    box-shadow: 0px 1px 4px 2px rgba(171, 171, 171, 0.1);
  }
  .bundleProducts .card {
    height: 220px;
  }
  .bundleProducts .icon-input {
    padding: 0 25px;
  }
  .card-img {
    height: 125px;
  }
  .card-img img {
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  .card-innr {
    padding: 10px;
  }
  .coun-part .input-with-label {
    margin-top: 5px;
  }
  @media (max-width: 575px) {
    .card-innr {
      padding: 0;
    }
    .coun-part {
      padding: 0 10px;
    }
    .icon-input {
      height: 40px;
    }
    .coun-part select {
      padding: 4px 20px 2px 15px !important;
      height: 32px !important;
    }
    .coun-part .select-wrapper:after {
      font-size: 9px;
    }
  }
  @media (min-width: 992px) {
    .coun-part {
      display: flex;
      justify-content: center;
    }
    .coun-part .input-with-label {
      margin-left: 0.5rem;
    }
  }
  .prod {
    padding-right: 0 !important;
  }
  .cal {
    font-size: 12px;
    cursor: pointer !important;
    color: rgba(6, 20, 53, 0.8);
  }
  .qty {
    width: 100%;
    text-align: center;
  }
  .select-wrapper:after {
    width: 30px !important;
  }
  .input-item {
    padding-bottom: 0;
  }
</style>
