<template>
  <div>
    <div class="modal fade" id="mobileMoney" tabindex="-1">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content py-0">
          <!-- <a href="#" class="modal-close" data-dismiss="modal" aria-label="Close"><em class="ti ti-close"></em></a> -->
          <div class="popup-body p-4">
            <div
              class="modal-header d-flex justify-content-between align-items-center pb-2 pt-0"
            >
              <div class="momo-img">
                <img src="@/assets/images/orchard.png" class="w-100" />
              </div>
              <div class="mo-user-details">
                <h6>
                  {{ user.country.currencySymbol }} {{ momo.orderTotalPrice }}
                </h6>
                <h6>{{ user.email }}</h6>
              </div>
            </div>
            <div class="form mt-2">
              <form @submit.prevent="payNow()">
                <div class="momo-form-group">
                  <label for=""
                    >Amount ({{ user.country.currencySymbol }})</label
                  >
                  <div class="input-wrapper">
                    <input
                      type="text"
                      :value="user.country.currencySymbol"
                      disabled
                      class="form-control ipt-sm"
                    />
                    <input
                      type="text"
                      :value="momo.orderTotalPrice"
                      disabled
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="momo-form-group">
                  <label for="">Phone Number</label>
                  <div class="input-wrapper">
                    <input
                      type="text"
                      :value="user.country.countryCode"
                      disabled
                      class="form-control ipt-sm"
                    />
                    <input
                      type="text"
                      v-model="user.phoneNumber"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="momo-form-group">
                  <label for="">Network</label>
                  <select name="" class="form-control" v-model="network">
                    <option value="AIR">AIR</option>
                    <option value="TIG">TIG</option>
                    <option value="VOD">VOD</option>
                    <option value="MTN">MTN</option>
                  </select>
                </div>
                <div class="momo-form-group">
                  <label for="">Add a note (optional)</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    class="form-control"
                  >
                    Write a short note
                  </textarea>
                </div>
                <div class="momo-submit my-4">
                  <button
                    type="submit"
                    class="btn btn-primary w-100"
                    :disabled="loading"
                  >
                    <span v-if="!loading">Pay Now</span>
                    <span v-else>Loading...</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>

    <div class="modal fade" id="orchardModalPending">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content py-0">
          <div class="popup-body p-4">
            <div
              class="modal-header d-flex justify-content-between align-items-center"
            >
              <div class="momo-img">
                <img src="@/assets/images/orchard.png" class="w-100" />
              </div>
              <div class="mo-user-details">
                <h6>
                  {{ user.country.currencySymbol }}{{ momo.orderTotalPrice }}
                </h6>
                <h6>{{ user.email }}</h6>
              </div>
            </div>
            <div class="row-block-display">
              <h6 class="text-tile text-center font-12 mb-4">
                We are confirming your payment. This could take a couple of
                minutes
              </h6>
              <div class="pending-confirmation">
                <!-- <div class="rotate"></div> -->
                <img
                  src="@/assets/images/rotate.png"
                  class="rotate"
                  width="100"
                  height="100"
                  alt=""
                />
                <span class="counter">{{ countDown }}</span>
              </div>
              <div class="payment-confirmation-block mb-4">
                <div class="payment-block payment-success mb-4">
                  <span class="text">Payment Made</span>
                  <span class="loader-done">
                    <img src="@/assets/images/group-5.svg" alt="" />
                  </span>
                </div>
                <div class="payment-block payment-pending mb-4">
                  <span class="text">Payment Confirmed</span>
                  <span class="loader-done">
                    <img
                      src="@/assets/images/89.gif"
                      width="25"
                      height="26"
                      alt=""
                    />
                  </span>
                </div>
              </div>
              <div class="text-tile-link">
                <a href="#" class="text-center text-tile mb-5">
                  Need help with the transactions?
                </a>
              </div>
              <div class="payment-lined-text">
                <img src="@/assets/images/Lock.svg" alt="" />
                <span>SECURED BY ORCHARD</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { mapState } from "vuex";
export default {
  props: {
    momo: {},
  },
  computed: {
    ...mapState({
      user: (context) => context.user,
    }),
  },
  data() {
    return {
      network: "",
      showVoucherCode: false,
      voucherCode: "",
      loading: false,
      countDown: 60,
    };
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    // selectNetwork(event) {
    //   if (event.target.value == "VOD") {
    //     this.showVoucherCode = true;
    //   } else {
    //     this.showVoucherCode = false;
    //   }
    // },
    checkPaymentStatus(payment) {
      // if (payment.paymentStatus == 2) {

      var vm = this;
      var interval = setInterval(function() {
        if (vm.countDown < 10) {
          window.ToasterAlert("error", "Outstanding transaction pending");
          setTimeout(() => {
            window.location.href = "/order/" + payment.orderId;
          }, 3000);
        }
        vm.$store
          .dispatch("post", {
            endpoint:
              "services/app/Orchard/CheckPaymentStatus?PaymentId=" + payment.id,
            details: {},
          })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.result.paymentStatus == 2) {
              vm.checkPaymentStatus(resp.data.result);
            } else if (resp.data.result.paymentStatus == 3) {
              window.ToasterAlert("error", "Transaction failed");
              window.location.href = "/order/" + payment.orderId;
            } else if (resp.data.result.paymentStatus == 1) {
              window.ToasterAlert("success", "Transaction success");
              window.location.href = "/order/" + payment.orderId;
            }
            clearInterval(interval);
          });
      }, 30000);
      // }
    },
    payNow() {
      // window.$("#mobileMoney").modal("hide");
      // window.$("#orchardModalPending").modal("show");
      // this.countDownTimer();

      if (this.user.phoneNumber.length < 1) {
        window.ToasterAlert("error", "Phone number field is required");
        return false;
      }
      if (this.network.length < 1) {
        window.ToasterAlert("error", "Network field is required");
        return false;
      }
      // if (this.network == "VOD" && this.voucherCode.length < 1) {
      //   window.ToasterAlert("error", "Voucher code field is required");

      //   return false;
      // }
      this.loading = true;
      const details = {
        orderId: this.momo.orderId,
        phoneNumber: this.user.country.countryCode + this.user.phoneNumber,
        network: this.network,
        voucherCode: this.voucherCode,
      };
      // console.log(details);

      this.$store
        .dispatch("post", {
          endpoint: "services/app/Orchard/InitiatePayment",
          details: details,
        })
        .then((resp) => {
          // console.log(resp);
          this.loading = false;
          if (resp.data.result.paymentStatus == 3) {
            window.ToasterAlert("error", "Transaction failed, try again");
            return false;
          }
          if (resp.data.result.paymentStatus == 2) {
            window.$("#mobileMoney").modal("hide");
            window.$("#orchardModalPending").modal("show");
            this.countDownTimer();
            this.checkPaymentStatus(resp.data.result);
          } else {
            this.checkPaymentStatus(resp.data.result);
          }
        });
    },
  },
  created() {},
};
</script>

<style scoped>
.counter {
  font-size: 35px;
  position: absolute;
  margin-top: -25px;
}
.rotate {
  color: white;
  animation: mymove 3s infinite;
  margin-bottom: 25px;
}

@keyframes mymove {
  100% {
    transform: rotate(360deg);
  }
}
.momo-form-group {
  margin-top: 25px;
}
.momo-form-group label {
  font-size: 14px;
}
.momo-form-group input {
  height: 45px;
  font-size: 14px;
}
.momo-form-group input::-webkit-input-placeholder {
  font-size: 14px;
}
.input-wrapper {
  display: flex;
}
.ipt-sm {
  width: 60px;
  text-align: center;
  margin-right: 10px;
}
.mo-user-details h6 {
  font-size: 12px;
  font-weight: bold;
}
.momo-img {
  width: 90px;
}
.pending-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-block-display {
  border-top: 1px solid rgba(0, 4, 45, 0.1);
  padding-top: 16px;
}
.row-block-display .text-tile {
  font-weight: 300;
  width: 75%;
  margin: 0 auto;
  color: #061435;
}
.payment-block {
  border: 1px solid rgba(6, 20, 53, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  margin-bottom: 14px;
}

.text-tile-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-tile-link a {
  text-decoration: underline;
}
.payment-lined-text {
  border: 1px solid rgba(6, 20, 53, 0.2);
  border-radius: 4px;
  padding: 10px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 70%;
}
.payment-lined-text span {
  color: #846100;
  font-size: 10px;
  font-weight: bolder;
}
</style>
