export default {
  setToken(state, token) {
    state.token = token;
  },
  setLang(state, lang) {
    state.language = lang;
  },
  addToCart(state, data) {
    state.cart.products.push(data);
    var result = encodeURIComponent(JSON.stringify(state.cart));
    localStorage.setItem("stockbooster_cart", result);
  },
  addToBundleOfferCart(state, data) {
    state.cart.bundleOffers.push(data);
    var result = encodeURIComponent(JSON.stringify(state.cart));
    localStorage.setItem("stockbooster_cart", result);
  },
  updateCart(state, data) {
    state.cart.products = [];
    state.cart.products = data;
    var result = encodeURIComponent(JSON.stringify(state.cart));
    localStorage.setItem("stockbooster_cart", result);
  },
  updateBundleOffersCart(state, data) {
    state.cart.bundleOffers = [];
    state.cart.bundleOffers = data;
    var result = encodeURIComponent(JSON.stringify(state.cart));
    localStorage.setItem("stockbooster_cart", result);
  },
  updateCartUnit(state, data) {
    // console.log(data)
    state.cart.products.forEach((product) => {
      if (product.product.productCode == data.code) {
        // console.log(product)
        product.prodUnitId = data.unit;
        // console.log(data.unit)
        product.cartUnitName = data.name;
        // console.log(state.cart.products)
        // console.log(product)
      }
    });
  },
  removeFromCart(state, id) {
    state.cart.products.forEach((prod, index) => {
      if (prod.product.productCode == id) {
        prod.quantity = 0;
        state.cart.products.splice(index, 1);
        // console.log(state.cart.products)
        var result = encodeURIComponent(JSON.stringify(state.cart));
        localStorage.setItem("stockbooster_cart", result);
      }
    });
  },
  removeFromBundleOfferCart(state, id) {
    state.cart.bundleOffers.forEach((prod, index) => {
      if (prod.productBundle.id == id) {
        prod.quantity = 0;
        state.cart.bundleOffers.splice(index, 1);
        // console.log(state.cart.products)
        var result = encodeURIComponent(JSON.stringify(state.cart));
        localStorage.setItem("stockbooster_cart", result);
      }
    });
  },

  getUser(state) {
    var data = localStorage.getItem("stockbooster");
    if (!data) {
      state.user = null;
      state.token = null;
      // window.location.href="/login";
    } else {
      data = decodeURIComponent(data);
      data = JSON.parse(data);
      // console.log(data)
      state.user = data;
      state.token = data.token;
      state.outlets = data.outlets;

      var result = encodeURIComponent(JSON.stringify(data));
      localStorage.setItem("stockbooster", result);
    }
  },
  setUser(state, data) {
    state.user = data;
    state.outlets = data.outlets;
    state.token = data.token;
    // console.log(state.user)
    var result = encodeURIComponent(JSON.stringify(data));
    localStorage.setItem("stockbooster", result);
  },

  setActiveOutlet(state, data) {
    var setData = {
      ...state.user,
      ...data.customer,
      territoryName: data.territoryName,
      volumeClass: data.volumeClass,
    };
    state.user = setData;
    // console.log(state)
    var result = encodeURIComponent(JSON.stringify(setData));
    localStorage.setItem("stockbooster", result);
  },
  logout(state) {
    // console.log(state)
    window.localStorage.removeItem("stockbooster");
    window.localStorage.removeItem("stockbooster_cart");
    state.user = null;
    state.token = null;
    window.location.href = "/";
  },
};
