<template>
  <div class="page-content">
    <div class="container">
      <div
        class="dash-header d-flex justify-content-between align-items-center my-4"
      >
        <h3 class="mb-0">Hi! {{ user.firstName }} {{ user.lastName }}</h3>
        <router-link to="/store" class="dash-new_order">
          <i class="fa fa-plus mr-1"></i> {{ $t("new_order") }}</router-link
        >
      </div>
      <div class="row kpi">
        <div class="col-md-3 col-6">
          <div class="card">
            <router-link class="card-wrapper" to="/store">
              <div class="card-header-txt font-weight-bold">
                <span class="new_order">{{ $t("new_order") }}</span>
              </div>
              <div
                class="card-header-img img-bg-grey d-none d-lg-flex"
                style="padding: 17px"
              >
                <img class="w-100" src="@/assets/images/plus.png" />
              </div>
            </router-link>
          </div>
        </div>

        <div class="col-md-3 col-6">
          <div class="card">
            <div class="card-wrapper">
              <div class="card-header-txt">
                <span>{{ $t("wallet_bal") }}</span>
                <h3 class="mb-0">
                  {{ this.$store.state.user.country.currency }} 0
                </h3>
              </div>
              <div class="card-header-img img-bg-blue d-none d-lg-block">
                <img class="w-100" src="@/assets/images/vector.svg" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-6">
          <div class="card">
            <div class="card-wrapper">
              <div class="card-header-txt">
                <span>{{ $t("p_orders") }}</span>
                <h3 class="mb-0">{{ pending }}</h3>
              </div>
              <div class="card-header-img img-bg-org d-none d-lg-block">
                <img class="w-100" src="@/assets/images/cartp.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="card">
            <div class="card-wrapper">
              <div class="card-header-txt">
                <span>{{ $t("loyal_point") }}</span>
                <h3 class="mb-0">0</h3>
              </div>
              <div class="card-header-img img-bg-pur d-none d-lg-block">
                <img class="w-100" src="@/assets/images/vectorhands.png" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-3 col-6">
                    <router-link to="/store">
                        <div class="token-information card card-full-height bg-primary">
                            <div class="token-info text-center">
                                <em class="ti ti-plus token-info-icon font-weight-bold text-white mb-1" style="font-size:40px"></em>
                                <span class="token-info-sub text-white">{{$t('new_order')}}</span>
                            </div>
                        </div>
                    </router-link>
                </div> -->
        <!-- <div class="col-md-3 col-6">
                    <div class="token-information card card-full-height bg-success">
                        <div class="token-info text-center">
                            <h1 class="token-info-head text-white" style="font-size:30px">{{this.$store.state.user.country.currency}} 0</h1>
                            <span class="token-info-sub text-white">{{$t('wallet_bal')}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6">
                    <div class="token-information card card-full-height bg-danger">
                        <div class="token-info text-center">
                            <h1 class="token-info-head text-white" style="font-size:40px">{{user.loyaltyPoint}}</h1>
                            <span class="token-info-sub text-white">{{$t('loyal_point')}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-6">
                    <div class="token-information card card-full-height bg-info">
                        <div class="token-info text-center py-2">
                            <div class="progress-circle p10">
                                <span>0%</span>
                                <div class="left-half-clipper">
                                    <div class="first50-bar"></div>
                                    <div class="value-bar"></div>
                                </div>
                            </div>
                            <span class="token-info-sub text-white">{{$t('target_actual')}}</span>
                        </div>
                    </div>
                </div> -->
      </div>
      <div class="row mt-3">
        <div class="col-lg-9">
          <div v-if="orders.length > 0">
            <div
              class="card-head d-flex justify-content-between align-items-center pb-2"
            >
              <h5 class="card-title c-black mb-0">{{ $t("re_orders") }}</h5>
              <div class="card-opt">
                <router-link to="/orders" class="link ucap c-black"
                  >{{ $t("v_all") }}
                  <em class="fas fa-angle-double-right ml-2"></em
                ></router-link>
              </div>
            </div>
            <div class="card content-area">
              <div class="card-innr px-0 pt-0">
                <div class="table-responsive">
                  <table class="data-table dt-init kyc-list table-striped">
                    <thead>
                      <tr class="data-item data-head">
                        <th class="table-col">{{ $t("Date") }}</th>
                        <th class="table-col">{{ $t("order_id") }}</th>
                        <th class="table-col">{{ $t("amount") }}</th>
                        <th class="table-col">{{ $t("confirmed") }}</th>
                        <th class="table-col">{{ $t("payment") }}</th>
                        <th class="table-col dt-status">{{ $t("status") }}</th>
                        <th class="table-col">{{ $t("action") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="data-item"
                        v-for="order in orders.slice(0, 10)"
                        :key="order.order.id"
                      >
                        <td class="table-col">
                          <span class="sub sub-s2">
                            {{ new Date(order.order.orderDate).toDateString() }}
                          </span>
                        </td>
                        <td class="table-col">
                          <span class="sub sub-s2">{{
                            order.order.orderNumber
                          }}</span>
                        </td>
                        <td class="table-col">
                          <span class="sub sub-s2"
                            >{{ user.country.currency }}
                            {{ formatPrice(order.order.orderTotalPrice) }}</span
                          >
                        </td>

                        <td class="table-col">
                          <div class="table-flex">
                            <div
                              class="dot"
                              :class="
                                order.order.isValidated
                                  ? 'dot-success'
                                  : 'dot-danger'
                              "
                            ></div>
                            <span
                              class="sub sub-s2"
                              :class="
                                order.order.isValidated
                                  ? 'dot-t-success'
                                  : 'dot-t-danger'
                              "
                              >{{ order.order.isValidated }}</span
                            >
                          </div>
                          <!-- <span class="sub sub-s2 dot" :class="order.order.isValidated?'dot-success':'dot-danger'">{{order.order.isValidated}}</span> -->
                        </td>
                        <td class="table-col">
                          <div class="table-flex">
                            <div
                              class="dot"
                              :class="
                                order.order.orderPaymentStatus == 0 ||
                                order.order.orderPaymentStatus == 3
                                  ? 'dot-danger'
                                  : order.order.orderPaymentStatus === 1
                                  ? 'dot-success'
                                  : 'dot-primary'
                              "
                            ></div>
                            <span
                              class="sub sub-s2"
                              v-if="order.order.orderPaymentStatus == 0"
                              >{{ $t("outstand") }}</span
                            >
                            <span v-if="order.order.orderPaymentStatus == 1"
                              >{{ $t("paid") }}
                            </span>
                            <span v-if="order.order.orderPaymentStatus == 2">{{
                              $t("InProgress")
                            }}</span>
                            <span v-if="order.order.orderPaymentStatus == 3">{{
                              $t("failed")
                            }}</span>
                          </div>
                        </td>
                        <td class="table-col">
                          <div class="table-flex">
                            <div
                              class="dot"
                              :class="
                                order.order.orderStatus == 0
                                  ? 'dot-warning'
                                  : order.order.orderStatus == 1
                                  ? 'dot-success'
                                  : 'dot-danger'
                              "
                            ></div>
                            <span
                              class="sub sub-s2"
                              :class="
                                order.order.orderStatus == 0
                                  ? 'dot-t-warning'
                                  : order.order.orderStatus == 1
                                  ? 'dot-t-success'
                                  : 'dot-t-danger'
                              "
                              >{{
                                order.order.orderStatus == 0
                                  ? $t("pending")
                                  : order.order.orderStatus == 1
                                  ? $t("delivered")
                                  : $t("cancelled")
                              }}</span
                            >
                          </div>
                          <!-- <span class="sub sub-s2 dot" :class="order.order.orderStatus == 0 ? 'dot-warning' : (order.order.orderStatus == 1 ? 'dot-success' : 'dot-danger')">
                                                        
                                                    </span> -->
                        </td>
                        <td class="table-col text-center">
                          <router-link
                            :to="'/order/' + order.order.id"
                            class="eye"
                            ><em class="ti ti-eye"></em
                          ></router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- .card-innr -->
            </div>
          </div>
          <div v-else class="alert alert-info text-center no-order">
            <h6>No recent orders</h6>
            <router-link to="/store" class="btn btn-primary"
              >Order Now</router-link
            >
          </div>
        </div>
        <div class="col-lg-3">
          <div class="row">
            <div class="col-12">
              <span class="side-label d-block">{{ $t("target_p") }}</span>
              <div class="token-information card card-full-height bg-white">
                <div class="token-info text-center py-2">
                  <div class="progress-circle p10">
                    <span>0%</span>
                    <small class="tg-middle">{{ $t("target_actual") }}</small>
                    <div class="left-half-clipper">
                      <div class="first50-bar"></div>
                      <div class="value-bar"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-12 mt-3">
                            <div class="card">
                                <div class="card-wrapper">
                                    <div class="card-header-txt">
                                        <span>{{$t('loyal_point')}}</span>
                                        <h1 class="mb-0 mt-4 lopoint">200</h1>
                                    </div>
                                    <div class="card-header-img img-bg-purple d-none d-lg-block">
                                        <img class="w-100" src="@/assets/images/vectorhands.png" />
                                    </div>
                                </div>
                            </div>
                        </div> -->
            <div class="col-12 mt-0">
              <span class="side-label d-block my-3">{{ $t("on_going") }}</span>
              <div class="token-statistics card card-token height-auto">
                <div class="card-innr">
                  <div class="token-balance mb-2">
                    <div class="token-balance-text">
                      <h6 class="card-sub-title">{{ $t("on_going") }}</h6>
                      <!-- <span class="lead"> 20 New Stores </span> -->
                    </div>
                  </div>
                  <div class="token-balance token-balance-s2">
                    <h6 class="card-sub-title">{{ $t("y_prog") }}</h6>
                    <ul class="token-balance-list">
                      <li class="token-balance-sub">
                        <span class="lead">0</span>
                        <span class="sub">{{ $t("opened") }}</span>
                      </li>
                      <li class="token-balance-sub">
                        <span class="lead">0</span>
                        <span class="sub">{{ $t("remain") }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- Advert section, might need it later -->
            <!-- <div class="col-12" v-if="user.country.tenantId == 10">
              <div class="token-information card card-full-height bg-white">
                <img src="@/assets/images/5.jpeg" alt="" />
              </div>
            </div>
            <div class="col-12" v-if="user.country.tenantId == 10">
              <div class="token-information card card-full-height bg-white">
                <img src="@/assets/images/6.jpeg" alt="" />
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState({
        user: (context) => context.user,
      }),
    },
    data() {
      return {
        delivered: 0,
        pending: 0,
        cancelled: 0,
        orders: [],
      };
    },
    methods: {
      formatPrice(value) {
        return new Intl.NumberFormat("en-NG", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
      },
      getHistory() {
        this.$store
          .dispatch(
            "get",
            "services/app/Orders/GetHistory?CustomerId=" + this.user.id
          )
          .then((resp) => {
            // console.log(resp)
            if (resp.data.success) {
              this.orders = resp.data.result;
              if (this.orders.length > 0) {
                this.orders.forEach((element) => {
                  if (element.order.orderStatus == 2) {
                    this.cancelled += 1;
                  } else if (element.order.orderStatus == 1) {
                    this.delivered += 1;
                  } else {
                    this.pending += 1;
                  }
                });
              }
            }
          });
      },
    },
    created() {
      this.getHistory();
    },
  };
</script>

<style scoped>
  /* .card-header-txt h3{
        text-transform: uppercase;
    } */
  .card-wrapper .card-header-txt .new_order {
    font-size: 16px;
  }
  .kpi .card {
    justify-content: center;
    height: 92px;
  }
  .card-title {
    font-size: 17px;
    font-weight: bold;
  }
  .data-head .table-col {
    text-transform: capitalize;
  }
  .eye em {
    font-size: 20px;
  }
  .table-col .sub-s2 {
    font-family: plus_jakarta_displayregular;
    color: #061435;
  }
  .img-bg-pur {
    background: rgba(0, 171, 208, 0.1);
  }
  .card-innr {
    padding: 19px 15px;
  }
  .token-balance .lead {
    font-size: 19px;
  }
  .card-sub-title {
    font-size: 11px;
  }
  .tg-middle {
    z-index: 99;
    font-size: 12px;
    margin-top: 40px;
    color: rgba(17, 42, 99, 0.7);
  }
  .side-label {
    margin-bottom: 6px;
    color: black;
    font-weight: bold;
    font-size: 17px;
  }
  .c-black {
    color: black;
  }
  .card-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
  }
  .lopoint {
    color: #00abd0;
  }
  .card-wrapper .card-header-img {
    height: 50px;
    width: 50px;
    padding: 8px;
    border-radius: 50%;
  }
  .img-bg-purple {
    background: rgba(0, 171, 208, 0.1);
  }
  .img-bg-blue {
    background: rgba(0, 21, 236, 0.1);
  }
  .img-bg-grey {
    background: rgba(6, 20, 53, 0.1);
  }
  .img-bg-org {
    background: rgba(255, 107, 0, 0.1);
  }
  .img-bg-red {
    background: rgba(255, 0, 0, 0.1);
  }
  .card-wrapper .card-header-txt span {
    font-size: 14px;
    font-family: plus_jakarta_displayregular;
  }
  .card-wrapper .card-header-txt h3 {
    font-weight: bold;
    font-size: 17px;
    text-transform: uppercase;
    margin-top: 15px;
    color: black;
  }
  .dash-header h3 {
    font-weight: bold;
    color: black;
  }
  .progress-circle.p10 .value-bar {
    transform: rotate(180deg);
  }
  .progress-circle {
    font-size: 20px;
    margin: 0;
    position: relative; /* so that children can be absolutely positioned */
    padding: 0;
    width: 6em;
    height: 6em;
    background-color: #fff;
    border-radius: 50%;
    /* line-height: 6em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 15px rgba(171, 171, 171, 0.2);
  }

  .progress-circle:after {
    border: none;
    position: absolute;
    top: 0.35em;
    left: 0.35em;
    text-align: center;
    display: block;
    border-radius: 50%;
    width: 5.3em;
    height: 5.3em;
    background-color: white;
    content: " ";
  }
  /* Text inside the control */
  .progress-circle span {
    position: absolute;
    line-height: 6em;
    width: 6em;
    text-align: center;
    display: block;
    color: #0015ec;
    z-index: 2;
  }
  .left-half-clipper {
    /* a round circle */
    border-radius: 50%;
    width: 6em;
    height: 6em;
    position: absolute; /* needed for clipping */
    clip: rect(0, 6em, 6em, 2.5em); /* clips the whole left half*/
  }
  .value-bar {
    /*This is an overlayed square, that is made round with the border radius,
        then it is cut to display only the left half, then rotated clockwise
        to escape the outer clipping path.*/
    position: absolute; /*needed for clipping*/
    clip: rect(0, 3em, 6em, 0);
    width: 6em;
    height: 6em;
    border-radius: 50%;
    border: 0.45em solid #0015ec; /*The border is 0.35 but making it larger removes visual artifacts */
    /*background-color: #4D642D;*/ /* for debug */
    box-sizing: border-box;
  }
  .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
  }
  .outs {
    padding: 2px 8px !important;
    width: max-content !important;
  }
  .rebtn {
    min-width: 114px;
  }
  thead .data-item {
    border-bottom: 1px solid #d2dde9;
  }
  thead th {
    padding-bottom: 12px !important;
  }

  .token-info-head {
    font-weight: bold;
  }
</style>
