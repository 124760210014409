<template>
  <!-- 716430328 -->
  <div class="page-ath-wrap d-flex justify-content-center align-items-center">
    <div class="page-ath-content">
      <div class="page-ath-header d-flex justify-content-center">
        <a href="./" class="page-ath-logo" style="width: 200px"
          ><img src="../assets/images/logo.png" class="w-100" alt="logo"
        /></a>
      </div>
      <div class="page-ath-form">
        <form @submit.prevent="getToken()">
          <div class="input-item input-with-label">
            <div class="relative">
              <span class="input-icon input-icon-left"
                ><em class="ti ti-world"></em
              ></span>
              <div class="select-wrapper">
                <select
                  @change="getCountryCode($event)"
                  class="input-bordered required valid s-drop"
                >
                  <option value=""></option>
                  <option
                    :selected="
                      code.tenantName == ipCountry ? selected : !selected
                    "
                    v-for="code in data"
                    :key="code.tenantId"
                    :value="code.tenantId"
                  >
                    {{ code.tenantName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="input-item input-with-label">
            <div class="relative">
              <span class="phone-image input-icon input-icon-left">
                <img src="@/assets/images/Lock.svg" alt="img" />
              </span>
              <!-- <span class="input-icon input-icon-left"
                ><em class="fa fa-phone" style="transform:rotate(90deg)"></em
              ></span> -->
              <input
                class="input-bordered"
                v-model="phone"
                type="text"
                :placeholder="$t('en_uid')"
              />
            </div>
          </div>
          <div class="">
            <div class="input-item text-left">
              <input
                class="input-checkbox input-checkbox-md"
                required
                id="age"
                type="checkbox"
              />
              <label for="age">{{ $t("cond") }}</label>
            </div>
            <div class="input-item text-left">
              <input
                class="input-checkbox input-checkbox-md"
                required
                id="terms"
                type="checkbox"
              />
              <label for="terms"
                >{{ $t("conf") }}
                <a href="#" class="font-weight-bold"> {{ $t("stoc") }} </a>
                {{ $t("of") }}
                <a href="#" class="font-weight-bold"> {{ $t("pric") }} </a
                >.</label
              >
            </div>
          </div>
          <button class="btn btn-primary btn-block" type="submit">
            <span v-if="!loading">{{ $t("login") }}</span>
            <span v-else>{{ $t("loading") }}</span>
          </button>
        </form>
      </div>
      <div class="text-center mb-3">
        <language />
      </div>

      <div style="height: 70px">
        <img
          src="@/assets/images/fimg3.png"
          class="w-100 h-100"
          style="object-fit: contain"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState({
        userState: (context) => context.user,
      }),
    },
    data() {
      return {
        data: [],
        user: [],
        country_code: "",
        phone: "",
        token: "",
        loading: false,
        country: {},
        ipCountry: "",
        loaded: false,
        selected: true,
      };
    },
    methods: {
      getCountryCode(event) {
        const talentId = event.target.value;

        this.data.forEach((element) => {
          if (element.tenantId == talentId) {
            this.country = element;
            this.country_code = element.urnoPrefix;
          }
        });
        // console.log(this.country)
      },
      login() {
        this.$store
          .dispatch(
            "get",
            "services/app/Customers/GetCustomerListByURNO?urno=" +
              this.country_code +
              "" +
              this.phone
          )
          .then((resp) => {
            this.loading = false;
            if (resp.status == 200) {
              var data = {
                ...resp.data.result[0].customer,
                country: this.country,
                token: this.$store.state.token,
                outlets: resp.data.result,
              };
              this.$store.commit("setUser", data);
              if (resp.data.result.length > 1) {
                this.$router.push("/outlets");
              } else {
                this.$router.push("/store");
              }
            }
          });
        this.loading = false;
      },
      getToken() {
        if (this.userState) {
          if (this.userState.token) {
            this.$router.push("/dashboard");
          }
        } else {
          if (this.phone.length < 1) {
            window.ToasterAlert("error", "Phone field is required");
            return false;
          }
          this.loading = true;
          this.$store
            .dispatch("post", {
              endpoint: "TokenAuth/Authenticate",
              details: {
                userNameOrEmailAddress: "webapp@mail.com",
                password: "Password123*",
                tenantId: this.country.tenantId,
              },
            })
            .then((resp) => {
              // console.log(resp)
              if (resp.status == 200) {
                this.token = resp.data.result.accessToken;
                this.$store.commit("setToken", this.token);
                this.login();
              }
            })
            .catch((error) => {
              this.$store.dispatch("handleError", error);
            });
        }
      },
      getMarket() {
        this.$store
          .dispatch("get", "services/app/EndMarkets/GetEndMarkets")
          .then((resp) => {
            if (resp.status == 200) {
              this.data = resp.data.result;
              this.getIp();
            }
          })
          .catch((error) => {
            this.$store.dispatch("handleError", error);
          });
      },
      getIp() {
        axios
          .get(
            "https://api.bigdatacloud.net/data/ip-geolocation?key=6e359050e938481b9853b2a7ca512bde"
          )
          .then((resp) => {
            this.ipCountry = resp.data.country.name;
            this.data.forEach((item) => {
              if (item.tenantName == resp.data.country.name) {
                this.country_code = item.urnoPrefix;
                this.country = item;
              }
              // if(item.urnoPrefix == this.country_code){
              //     this.country = item
              // }
            });
          });
      },
    },
    created() {
      this.$store.commit("getUser");
      // console.log(this.userState)
      this.getMarket();
    },
  };
</script>
<style scoped>
  .page-ath-content {
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
      0 15px 40px rgba(166, 173, 201, 0.2);
  }
  label {
    line-height: 19px !important;
  }
  .s-drop {
    padding-left: 35px;
  }
  @media (min-width: 576px) {
    .s-drop {
      padding-left: 50px;
    }
  }
</style>
