export default {
  age_limit: "YOU MUST BE 18 YEARS AND ABOVE TO USE THIS WEBSITE",
  under_age: "I AM UNDER THE AGE OF 18",
  of_age: "I AM OVER 18 YEARS OLD",
  access_denied: "You can't access this website!!!",
  failed: "Failed",
  InProgress: "In Progress",
  valid_code: "Validation code",
  en_uid: "Enter your Unique Id",
  orchard: "Orchard",
  switch_outlet: "Switch Outlet",
  select_store: "Multiple Outlets",
  txt1: "Please select one outlet to proceed",
  proceed: "Proceed",
  s_name: "Outlet name",
  target_p: "Target Progress",
  on_going: "Ongoing Campaigns",
  y_prog: "Your Progress",
  opened: "Opened",
  remain: "Remain",
  order_no: "Order number",
  order_val: "Order validation",
  edit: "Edit",
  back: "Back",
  order_n: "Order number",
  up_order: "Update order",
  e_order: "Edit Order",
  add_new_prod: "Add new product",
  products: "Products",
  quantity: "Quantity",
  add_prod: "Add Product",
  order_id: "Order Id",
  amount: "Amount",
  date: "Date",
  action: "Action",
  loyal_point: "Loyalty Point",
  target_reached: "Target Reach",
  login: "Login",
  wallet_bal: "Wallet Balance",
  target_actual: "Monthly target",
  loading: "Loading...",
  username: "Username",
  password: "Password",
  orders: "Orders",
  dashboard: "Dashboard",
  store: "Store",
  customers: "Customers",
  welcome: "Welcome!",
  logout: "Logout",
  new_order: "New Order",
  pending: "Pending",
  p_orders: "Pending Orders",
  v_all: "View All",
  id: "id",
  client: "client",
  price: "price",
  time: "time",
  status: "status",
  name: "name",
  phone: "Phone",
  f_txt1:
    "For Trade Purposes. Not for Circulation or Distribution to the General Public.",
  f_c: "TRADERPADi - All rights reserved.",
  payment: "payment",
  delivered: "Delivered",
  cancelled: "Cancelled",
  o_details: "Order Details",
  o_status: "Order Status",
  o_pstats: "Order Payment Status",
  p_status: "Payment Status",
  p_method: "Payment Method",
  to_quant: "Total Order Quantity",
  o_date: "Order Date",
  total: "Total",
  s_n: "S/N",
  product: "product",
  unit: "Unit",
  qty: "qty",
  d_order: "Deliver Order",
  c_order: "Cancel Order",
  print: "print",
  search: "search",
  history: "history",
  address: "address",
  direction: "direction",
  channel: "channel",
  o_for: "Order for",
  cash: "cash",
  credit: "credit",
  pos: "pos",
  transfer: "transfer",
  subtotal: "Subtotal",
  order: "Order",
  paid: "Paid",
  checkout: "Checkout",
  go_back: "Go Back",
  noitem: "No item in your cart",
  sub: "Submitting...",
  s_order: "Submit Order",
  c_shop: "Continue Shopping",
  r_order: "Review Order",
  cond: "I confirm that I am a trader dealing with BAT products and I am 18 years or older.",
  conf: "I confirm I have read and accept the",
  stoc: "TRADERPADi Terms and Conditions",
  pric: "Use and Privacy Policy",
  of: "Of",
  outstand: "Outstanding",
  confirmed: "Confirmed",
  re_orders: "Recent Orders",
  co_order: "Confirm Order",
  ca_order: "Cancel Order",
  pr_order: "Print Order",
  cus_ref: "Customer reference number",
};
