/* eslint-disable no-useless-escape */
import Vue from "vue";
import VueRouter from "vue-router";
import login from "../components/login";
import age from "../components/age";
import underAge from "../components/underAge";
import outlets from "../components/outlets";
import chatbot from "../components/chatbot";
import layout from "../components/dashboard/layout";
import dashboard from "../components/dashboard/dashboard";
import orders from "../components/dashboard/orders";
import order from "../components/dashboard/order";
import checkout from "../components/dashboard/checkout";
import products from "../components/dashboard/products";
import storeProducts from "../components/dashboard/storeProducts";
import bundleOffers from "../components/dashboard/bundleOffers";
import bundleCheckOut from "../components/dashboard/bundleCheckOut";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: age, name: "age" },
  { path: "/under-age", component: underAge, name: "underAge" },
  { path: "/login", component: login, name: "login" },
  { path: "/outlets", component: outlets, name: "outlets" },
  { path: "/chatbot", component: chatbot, name: "chatbot" },
  {
    path: "/",
    component: layout,
    children: [
      { path: "/dashboard", component: dashboard, name: "dashboard" },
      { path: "/orders", component: orders, name: "orders" },
      { path: "/order/:id", component: order, name: "order" },
      { path: "/checkout", component: checkout, name: "checkout" },
      { path: "/store", component: products, name: "products" },
      {
        path: "/store-products",
        component: storeProducts,
        name: "storeProducts",
      },
      {
        path: "/bundle-offers",
        component: bundleOffers,
        name: "bundleOffers",
      },
      {
        path: "/bundle-checkout",
        component: bundleCheckOut,
        name: "bundleCheckOut",
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  const mobileCheck =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  if (mobileCheck) {
    window.location.href = process.env.VUE_APP_MOBILE_ENDPOINT + to.path;
  }
  next();
});

export default router;
