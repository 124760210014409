<template>
  <div class="page-content">
    <div class="container">
      <div class="row mt-3">
        <div
          :class="cart.bundleOffers.length > 0 ? 'col-sm-9' : ''"
          class="col-12"
        >
          <div class="row">
            <div class="col-12">
              <div class="product-title d-flex align-items-center mb-3">
                <p class="font-weight-bold mb-0">Bundle Offer</p>
                <img src="@/assets/images/4.svg" class="ml-2" alt="" />
              </div>
            </div>
            <div
              :class="
                cart.bundleOffers.length > 0 ? 'col-md-6 col-lg-4' : 'col-md-3'
              "
              class="col-6 bundleProducts"
              v-for="product in bundleProducts"
              :key="product.productBundle.id"
            >
              <div class="content-area card products-pg">
                <div class="card-innr">
                  <div class="card-img text-center">
                    <img :src="product.productBundle.imageURL" alt="" />
                  </div>
                  <div class="mt-2 text-center">
                    <span class="prod-name">{{
                      product.productBundle.displayName
                    }}</span>
                  </div>
                  <div class="mt-1 text-center">
                    <span class="prod-price"
                      >{{ user.country.currencySymbol }}
                      {{ product.productBundle.unitPrice }}</span
                    >
                  </div>
                  <div class="coun-part mt-2">
                    <div
                      class="input-item input-with-label d-flex align-items-center ic-qty"
                    >
                      <div
                        class="icon-input input-right"
                        @click="minusQty(product.productBundle.id)"
                      >
                        <em class="fa fa-minus cal"></em>
                      </div>
                      <input
                        class="input-bordered qty pl-0 border-0"
                        min="0"
                        @input="changeQty(product.productBundle.id)"
                        :id="product.productBundle.id"
                        type="number"
                        :value="product.quantity"
                      />
                      <div
                        class="icon-input input-left"
                        @click="addQty(product.productBundle.id)"
                      >
                        <em class="fa fa-plus cal"></em>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="cart.bundleOffers.length > 0" class="col-sm-3 col-12">
          <div class="row cart-order px-1">
            <div class="col-12">
              <div
                class="cart-order-header d-flex justify-content-between align-items-center py-2 pt-4"
              >
                <h5 class="mb-0">YOUR ORDER</h5>
                <i class="fa fa-times"></i>
              </div>
            </div>
            <div
              class="col-12"
              v-for="(product, index) in cart.bundleOffers"
              :key="index"
            >
              <div class="cart-product">
                <div class="cart-prod-img">
                  <img :src="product.productBundle.imageURL" />
                </div>
                <div class="cart-prod-details">
                  <div class="cart-prod-content">
                    <h6 class="mb-0">
                      {{ product.productBundle.displayName }}
                    </h6>
                    <!-- <h6 class="font-weight-light cart-prod txt-color">QTY: <span :class="'cart'+index">{{product.quantity}} </span> </h6> -->
                    <h5 class="font-weight-bold cpc-price">
                      {{ user.country.currency }}
                      {{
                        formatPrice(
                          product.productBundle.unitPrice * product.quantity
                        )
                      }}
                    </h5>
                    <div
                      class="input-item input-with-label d-flex align-items-center ic-qty"
                    >
                      <div
                        class="icon-input input-right"
                        @click="minusQty(product.productBundle.id)"
                      >
                        <em class="fa fa-minus cal"></em>
                      </div>
                      <input
                        class="input-bordered qty pl-0"
                        min="0"
                        :class="'cart' + index"
                        @input="changeQty(product.productBundle.id)"
                        type="number"
                        :value="product.quantity"
                      />
                      <div
                        class="icon-input input-left"
                        @click="
                          addQty(product.productBundle.id, 'cart' + index)
                        "
                      >
                        <em class="fa fa-plus cal"></em>
                      </div>
                    </div>
                  </div>
                  <i
                    class="fa fa-trash text-danger mt-3 toggle-tigger"
                    @click="deleteItemFromCart(product.productBundle.id)"
                  ></i>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="cart-subtotal d-flex justify-content-between py-3">
                <h5>Subtotal:</h5>
                <h5>
                  {{ user.country.currency }} {{ formatPrice(calculateTotal) }}
                </h5>
              </div>
            </div>
            <div class="col-12">
              <button
                class="btn btn-primary w-100 mb-4"
                @click="checkOut(true)"
              >
                {{ $t("checkout") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState({
        cart: (context) => context.cart,
        user: (context) => context.user,
      }),
      calculateTotal() {
        let total = 0;
        this.cart.bundleOffers.forEach((element) => {
          let price = element.quantity * element.productBundle.unitPrice;
          total += price;
        });
        return total.toFixed(2);
      },
    },
    data() {
      return {
        products: [],
        convert: {},
        setTrue: true,
        productPrices: [],
        bundleProducts: [],
      };
    },
    methods: {
      formatPrice(value) {
        var hold = parseFloat(value).toFixed(2);
        return new Intl.NumberFormat("en-NG", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(hold);
      },
      changeQty(id) {
        var num = parseFloat(document.getElementById(id).value);
        if (!isNaN(num)) {
          document.getElementById(id).value = num;
          this.addToCart(id, num);
        }
      },
      checkOut(route = false) {
        var chold = this.cart.bundleOffers;
        this.$store.commit("updateBundleOffersCart", chold);
        if (route) {
          this.$router.push("bundle-checkout");
        }
      },
      minusQty(id) {
        var num = document.getElementById(id).value;
        if (num > 0) {
          document.getElementById(id).value = parseInt(num - 1);
          this.removeFromCart(id);
        }
      },
      addQty(id) {
        var num = document.getElementById(id).value;
        document.getElementById(id).value = parseInt(num) + 1;
        this.addToCart(id);
      },
      getBundleOffers() {
        this.$store
          .dispatch(
            "get",
            "services/app/ProductBundle/GetAllForCustomer?MaxResultCount=1000&customerId=" +
              this.user.id
          )
          .then((resp) => {
            this.bundleProducts = resp.data.result.items;
            this.bundleProducts.forEach((prod) => {
              prod.quantity = 0;
            });
            if (this.cart.bundleOffers.length > 0) {
              this.bundleProducts.forEach((prod) => {
                this.cart.bundleOffers.forEach((item) => {
                  if (item.productBundle.id == prod.productBundle.id) {
                    prod.quantity = item.quantity;
                  }
                });
              });
            }
            console.log(this.bundleProducts);
          });
      },
      checkCart(id) {
        var stat = false;
        this.cart.bundleOffers.forEach((prod) => {
          if (prod.productBundle.id == id) {
            prod.quantity = document.getElementById(id).value;
            stat = true;

            var result = encodeURIComponent(JSON.stringify(this.cart));
            localStorage.setItem("stockbooster_cart", result);
            this.$store.commit(
              "updateBundleOffersCart",
              this.cart.bundleOffers
            );
          }
        });
        return stat;
      },
      deleteItemFromCart(id) {
        this.$store.commit("removeFromBundleOfferCart", id);
      },
      removeFromCart(id) {
        this.cart.bundleOffers.forEach((prod) => {
          if (prod.productBundle.id == id) {
            if (prod.quantity == 1) {
              // this.cart.products.splice(index, 1)
              this.deleteItemFromCart(id);
              document.getElementById(id).value = 0;
            } else {
              prod.quantity -= 1;
            }
            var result = encodeURIComponent(JSON.stringify(this.cart));
            localStorage.setItem("stockbooster_cart", result);
            this.checkOut();
            this.$store.commit(
              "updateBundleOffersCart",
              this.cart.bundleOffers
            );
          }
        });
      },
      addToCart(id, qty = 1) {
        var check = this.checkCart(id);
        if (!check) {
          this.bundleProducts.forEach((element) => {
            if (element.productBundle.id == id) {
              element.quantity = qty;
              this.$store.commit("addToBundleOfferCart", element);
            }
          });
        }
        this.checkOut();
      },
    },
    created() {
      this.getBundleOffers();
    },
  };
</script>

<style scoped>
  .product-title img {
    width: 25px;
  }
  .bundleProducts .prod-name {
    font-size: 12px;
  }
  .prod-name {
    font-size: 13px;
    color: #061435;
    font-family: plus_jakarta_displayregular;
    line-height: 16px;
    display: block;
    /* font-weight: 400; */
  }
  .cart-subtotal h5 {
    color: #061435;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .txt-color {
    color: #6e81a9;
  }
  .cart-prod-content select {
    border: none;
  }
  .cart-prod-content .input-bordered {
    width: 43px;
    border: 1px solid rgba(17, 42, 99, 0.15);
    border-radius: 0;
    padding: 6px 0;
  }
  .cart-prod-content .input-right {
    border: 1px solid rgba(17, 42, 99, 0.15);
    border-right: none;
  }
  .cart-prod-content .input-left {
    border: 1px solid rgba(17, 42, 99, 0.15);
    border-left: none;
  }
  .cart-prod-content .ic-qty {
    border: none;
  }
  .cart-prod-content .icon-input {
    padding: 10px 8px;
  }
  .cpc-price {
    font-size: 17px;
    color: black;
  }
  .cart-prod-select select {
    font-size: 15px;
  }
  .cart-prod-select:after {
    font-size: 15px;
  }
  .cart-prod-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
  }
  .cart-prod-content {
    color: #061435;
  }
  .cart-prod-content h6 {
    font-weight: bold;
  }
  .cart-prod-img {
    width: 55px;
  }
  .cart-product {
    display: flex;
    margin-top: 26px;
    border-bottom: 1px solid rgba(17, 42, 99, 0.15);
    padding-bottom: 20px;
  }
  .cart-order-header h5 {
    color: black;
  }
  .cart-order-header .fa {
    font-size: 21px;
  }
  .cart-order-header {
    border-bottom: 1px solid rgba(6, 20, 53, 0.14);
  }
  .cart-order {
    background: #fff;
  }
  .select-wrapper select {
    border: none;
    padding: 0;
  }
  .select-wrapper {
    width: 70px;
  }
  .prod-price {
    color: black;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .float-co {
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 20px;
    right: 20px;
    background: #28a745;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    height: 65px;
    width: 65px;
  }
  .float-co em {
    font-size: 19px;
  }
  .float-co span {
    display: block;
    font-size: 12px;
  }
  .ic-qty {
    border: 1px solid rgba(17, 42, 99, 0.15);
    border-radius: 4px;
    border-right: 0;
    border-left: 0;
  }
  .icon-input {
    background-color: transparent;
    color: black;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  .icon-input:hover .fa {
    color: #fff;
  }
  .icon-input:hover {
    background: #061435;
    color: #fff;
    transition: all 0.5s;
  }
  .input-right {
    border-left: 1px solid rgba(17, 42, 99, 0.15);
    border-right: 1px solid rgba(17, 42, 99, 0.15);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .input-left {
    border-right: 1px solid rgba(17, 42, 99, 0.15);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid rgba(17, 42, 99, 0.15);
  }
  .card {
    box-shadow: 0px 1px 4px 2px rgba(171, 171, 171, 0.1);
  }
  .bundleProducts .card {
    height: 267px;
  }
  .bundleProducts .icon-input {
    padding: 0 25px;
  }
  .card-img {
    height: 125px;
  }
  .card-img img {
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  .card-innr {
    padding: 10px;
  }
  .coun-part .input-with-label {
    margin-top: 5px;
  }
  @media (max-width: 575px) {
    .card-innr {
      padding: 0;
    }
    .coun-part {
      padding: 0 10px;
    }
    .icon-input {
      height: 40px;
    }
    .coun-part select {
      padding: 4px 20px 2px 15px !important;
      height: 32px !important;
    }
    .coun-part .select-wrapper:after {
      font-size: 9px;
    }
  }
  @media (min-width: 992px) {
    .coun-part {
      display: flex;
      justify-content: center;
    }
    .coun-part .input-with-label {
      margin-left: 0.5rem;
    }
  }
  .prod {
    padding-right: 0 !important;
  }
  .cal {
    font-size: 12px;
    cursor: pointer !important;
    color: rgba(6, 20, 53, 0.8);
  }
  .qty {
    width: 100%;
    text-align: center;
  }
  .select-wrapper:after {
    width: 30px !important;
  }
  .input-item {
    padding-bottom: 0;
  }
</style>
