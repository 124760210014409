export default {
  age_limit: "VOUS DEVEZ AVOIR 18 ANS ET PLUS POUR UTILISER CE SITE WEB",
  under_age: "J'AI MOINS DE 18 ANS",
  of_age: "J'AI PLUS DE 18 ANS",
  access_denied: "Vous ne pouvez pas accéder à ce site !!!",
  failed: "Échoué",
  InProgress: "En cours",
  valid_code: "Code de validation",
  en_uid: "Entrez votre identifiant unique",
  orchard: "Orchard",
  switch_outlet: "Commutateur de sortie",
  select_store: "Prises multiples",
  txt1: "Veuillez sélectionner une prise pour continuer",
  proceed: "Procéder",
  s_name: "Nom du point de vente",
  target_p: "Progrès ciblé",
  on_going: "Campagnes en cours",
  y_prog: "Votre progression",
  opened: "Ouvert",
  remain: "Rester",
  order_no: "Numéro de commande",
  order_val: "Validation de la commande",
  edit: "Éditer",
  back: "Arrière",
  order_n: "Numéro de commande",
  up_order: "Mise à jour de la commande",
  e_order: "Modifier la commande",
  add_new_prod: "Ajouter un nouveau produit",
  products: "Des produits",
  quantity: "Quantité",
  add_prod: "Ajouter un produit",
  order_id: "Numéro de commande",
  amount: "Montante",
  date: "Date",
  action: "Action",
  loyal_point: "Points de fidélité",
  target_reached: "Portée cible",
  login: "Connexion",
  wallet_bal: "Solde du portefeuille",
  target_actual: "Objectif mensuel",
  loading: "Chargement...",
  username: "Nom d'utilisateur",
  password: "le mot de passe",
  orders: "Commandes",
  dashboard: "Tableau de bord",
  store: "Magasin",
  customers: "Clients",
  welcome: "Bienvenue !",
  logout: "Déconnexion",
  new_order: "Nouvelle commande",
  pending: "En attente",
  p_orders: "Commandes en attente",
  v_all: "Tout afficher",
  id: "id",
  client: "client",
  price: "prix",
  time: "heure",
  status: "statut",
  name: "nom",
  phone: "téléphone",
  f_txt1:
    "À des fins commerciales. Ne pas diffuser ou distribuer au grand public.",
  f_c: "TRADERPADi - Tous droits réservés.",
  payment: "paiement",
  delivered: "Livré",
  cancelled: "Annulé",
  o_details: "Détails de la commande",
  o_status: "Statut de la commande",
  o_pstats: "Statut de paiement de la commande",
  p_status: "Statut de paiement",
  p_method: "Mode de paiement",
  to_quant: "Quantité totale de commande",
  o_date: "Date de commande",
  total: "Totale",
  s_n: "S/N",
  product: "produit",
  unit: "unité",
  qty: "quantité",
  d_order: "Livrer la commande",
  c_order: "annuler la commande",
  print: "imprimer",
  search: "chercher",
  history: "l'histoire",
  address: "adresse",
  direction: "direction",
  channel: "canal",
  o_for: "Comander pour",
  cash: "argent",
  credit: "crédit",
  pos: "Pos",
  transfer: "transfert",
  subtotal: "Sous-total",
  order: "Ordre",
  paid: "Payé",
  checkout: "Vérifier",
  go_back: "Retour",
  noitem: "Aucun article dans votre panier",
  sub: "Envoi...",
  s_order: "Envoi de la commande",
  c_shop: "Continuer les achats",
  r_order: "Réviser la commande",
  cond: "Je confirme que je suis un commerçant traitant des produits BAT et que j'ai 18 ans ou plus.",
  conf: "Je confirme avoir lu et accepté le",
  stoc: "Conditions Générales de TRADERPADi",
  pric: "Utilisation et politique de confidentialité",
  of: "de",
  outstand: "En circulation",
  confirmed: "Confirmé",
  re_orders: "Commandes récentes",
  co_order: "Confirmer la commande",
  ca_order: "Annuler la commande",
  pr_order: "Imprimer la commande",
  cus_ref: "Numéro de référence du client",
};
