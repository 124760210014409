<template>
  <div class="page-content">
    <div class="container mt-3">
      <div class="row">
        <div class="col-md-3 col-6">
          <router-link to="/store">
            <div class="token-information card card-full-height bg-primary">
              <div class="token-info text-center">
                <em
                  class="ti ti-plus token-info-icon font-weight-bold text-white mb-1"
                  style="font-size: 40px"
                ></em>
                <span class="token-info-sub text-white">{{
                  $t("new_order")
                }}</span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-3 col-6">
          <div class="token-information card card-full-height bg-success">
            <div class="token-info text-center">
              <h1 class="token-info-head text-white" style="font-size: 40px">
                {{ delivered }}
              </h1>
              <span class="token-info-sub text-white">{{
                $t("delivered")
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="token-information card card-full-height bg-danger">
            <div class="token-info text-center">
              <h1 class="token-info-head text-white" style="font-size: 40px">
                {{ pending }}
              </h1>
              <span class="token-info-sub text-white">{{ $t("pending") }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <div class="token-information card card-full-height bg-info">
            <div class="token-info text-center">
              <h1 class="token-info-head text-white" style="font-size: 40px">
                {{ cancelled }}
              </h1>
              <span class="token-info-sub text-white">{{
                $t("cancelled")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card content-area">
        <div class="card-innr">
          <div class="card-head">
            <h4 class="card-title">{{ $t("orders") }}</h4>
          </div>
          <div class="table-responsive" v-if="orders.length > 0">
            <table class="data-table dt-init kyc-list table-striped">
              <thead>
                <tr class="data-item data-head">
                  <th class="table-col"></th>
                  <th class="table-col">{{ $t("id") }}</th>
                  <th class="table-col">{{ $t("price") }}</th>
                  <th class="table-col">{{ $t("time") }}</th>
                  <th class="table-col">{{ $t("confirmed") }}</th>
                  <th class="table-col">{{ $t("valid_code") }}</th>
                  <th class="table-col">{{ $t("payment") }}</th>
                  <th class="table-col dt-status">{{ $t("status") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="data-item"
                  v-for="order in orders"
                  :key="order.order.id"
                >
                  <td class="table-col">
                    <router-link
                      :to="'/order/' + order.order.id"
                      class="btn btn-light-alt btn-xs btn-icon"
                      ><em class="ti ti-eye"></em
                    ></router-link>
                  </td>
                  <td class="table-col">
                    <span class="sub sub-s2">{{
                      order.order.orderNumber
                    }}</span>
                  </td>
                  <td class="table-col">
                    <span class="sub sub-s2"
                      >{{ user.country.currency }}
                      {{ formatPrice(order.order.orderTotalPrice) }}</span
                    >
                  </td>
                  <td class="table-col">
                    <span class="sub sub-s2">
                      <!-- {{new Date(Date(order.order.orderDate)).toDateString()}} -->
                      {{ new Date(order.order.orderDate).toDateString() }}
                    </span>
                    <!-- {{}} -->
                  </td>
                  <td class="table-col">
                    <div class="table-flex">
                      <div
                        class="dot"
                        :class="
                          order.order.isValidated ? 'dot-success' : 'dot-danger'
                        "
                      ></div>
                      <span class="sub sub-s2">{{
                        order.order.isValidated
                      }}</span>
                    </div>
                    <!-- <span class="sub sub-s2 dot" :class="order.order.isValidated?'dot-success':'dot-danger'">{{order.order.isValidated}}</span> -->
                  </td>
                  <td class="table-col">
                    <span class="sub sub-s2">{{
                      order.order.validationCode
                    }}</span>
                    <!-- <span class="sub sub-s2 dot" :class="order.order.isValidated?'dot-success':'dot-danger'">{{order.order.isValidated}}</span> -->
                  </td>
                  <td class="table-col">
                    <div class="table-flex">
                      <div
                        class="dot"
                        :class="
                          order.order.orderPaymentStatus == 0 ||
                          order.order.orderPaymentStatus == 3
                            ? 'dot-danger'
                            : order.order.orderPaymentStatus === 1
                            ? 'dot-success'
                            : 'dot-primary'
                        "
                      ></div>
                      <span
                        class="sub sub-s2"
                        v-if="order.order.orderPaymentStatus == 0"
                        >{{ $t("outstand") }}</span
                      >
                      <span v-if="order.order.orderPaymentStatus == 1"
                        >{{ $t("paid") }}
                      </span>
                      <span v-if="order.order.orderPaymentStatus == 2">{{
                        $t("InProgress")
                      }}</span>
                      <span v-if="order.order.orderPaymentStatus == 3">{{
                        $t("failed")
                      }}</span>
                    </div>
                  </td>
                  <td class="table-col">
                    <div class="table-flex">
                      <div
                        class="dot"
                        :class="
                          order.order.orderStatus == 0
                            ? 'dot-warning'
                            : order.order.orderStatus == 1
                            ? 'dot-success'
                            : 'dot-danger'
                        "
                      ></div>
                      <span class="sub sub-s2">{{
                        order.order.orderStatus == 0
                          ? $t("pending")
                          : order.order.orderStatus == 1
                          ? $t("delivered")
                          : $t("cancelled")
                      }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="alert alert-info text-center no-order">
            <h6>No recent orders</h6>
            <router-link to="/store" class="btn btn-primary"
              >Order Now</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState({
        user: (context) => context.user,
      }),
    },
    data() {
      return {
        delivered: 0,
        pending: 0,
        cancelled: 0,
        orders: [],
      };
    },
    methods: {
      formatPrice(value) {
        return new Intl.NumberFormat("en-NG", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
      },
      getOrders() {
        this.$store
          .dispatch(
            "get",
            "services/app/Orders/GetHistory?CustomerId=" + this.user.id
          )
          .then((resp) => {
            if (resp.data.success) {
              this.orders = resp.data.result;
              if (this.orders.length > 0) {
                this.orders.forEach((element) => {
                  if (element.order.orderStatus == 2) {
                    this.cancelled += 1;
                  } else if (element.order.orderStatus == 1) {
                    this.delivered += 1;
                  } else {
                    this.pending += 1;
                  }
                });
              }
            }
          });
      },
    },
    created() {
      this.getOrders();
    },
  };
</script>

<style scoped>
  thead .data-item {
    border-bottom: 1px solid #d2dde9;
  }
  thead th {
    padding-bottom: 12px !important;
  }
  .data-head .data-col {
    color: #495463;
  }
</style>
