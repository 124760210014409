<template>
  <div class="page-content">
    <div class="container mt-3" v-if="ready">
      <div
        class="card-head d-flex justify-content-between align-items-center mb-0 pb-2"
      >
        <h4 class="card-title mb-0 cap" v-if="viewMode">
          {{ $t("o_details") }}
        </h4>
        <h4 class="card-title mb-0 cap" v-else>
          {{ $t("e_order") }}
        </h4>
        <div>
          <button
            v-if="
              order.order.orderStatus === 0 &&
              order.order.orderPaymentStatus == 0
            "
            class="btn btn-primary edit-icon"
            @click="toggleEditMode()"
          >
            <span v-if="viewMode">
              <i class="fa fa-edit mr-2"></i> {{ $t("e_order") }}
            </span>
            <span v-else>
              <i class="fa fa-chevron-left mr-2"></i> {{ $t("back") }}
            </span>
          </button>
          <button
            v-if="
              order.order.orderPaymentStatus == 0 ||
              order.order.orderPaymentStatus == 3
            "
            class="btn btn-primary ml-1"
            data-toggle="modal"
            data-target="#editPaymentMethod"
          >
            Pay Now
          </button>
          <button
            v-if="
              order.order.orderPaymentMethod == 4 &&
              order.order.orderPaymentStatus == 2
            "
            class="btn btn-primary ml-1"
            @click="checkOrchardPaymentStatus()"
            :disabled="loading"
          >
            {{ loading ? "Loading..." : "Check Status" }}
          </button>
        </div>
      </div>
      <div class="row" v-if="viewMode">
        <div class="col-sm-5">
          <div class="card content-area">
            <div class="card-innr pt-5 px-4">
              <div class="data-details">
                <h2 class="card-sub-name mb-2 font-weight-bold">
                  {{ order.customerFirstName }}
                </h2>
                <div class="">
                  <div class="fake-class">
                    <span class="data-details-title"
                      >{{ $t("order_no") }}:</span
                    >
                    <span class="data-details-info dd-info">{{
                      order.order.orderNumber
                    }}</span>
                  </div>
                  <div class="fake-class">
                    <span class="data-details-title"> {{ $t("o_date") }}:</span>
                    <span class="data-details-info dd-info">{{
                      new Date(order.order.orderDate).toDateString()
                    }}</span>
                  </div>
                  <div class="fake-class">
                    <span class="data-details-title"
                      >{{ $t("to_quant") }}:</span
                    >
                    <span class="data-details-info dd-info">{{
                      formatPrice(order.order.orderTotalQuantity)
                    }}</span>
                  </div>
                  <div class="fake-class">
                    <span class="data-details-title"
                      >{{ $t("p_method") }}:</span
                    >
                    <span
                      class="data-details-info dd-info"
                      v-if="order.order.orderPaymentMethod == 0"
                      >{{ $t("cash") }}</span
                    >
                    <span
                      class="data-details-info dd-info"
                      v-if="order.order.orderPaymentMethod == 1"
                      >{{ $t("credit") }}</span
                    >
                    <span
                      class="data-details-info dd-info"
                      v-if="order.order.orderPaymentMethod == 2"
                      >{{ $t("pos") }}</span
                    >
                    <span
                      class="data-details-info dd-info"
                      v-if="order.order.orderPaymentMethod == 3"
                      >{{ $t("transfer") }}</span
                    >
                    <span
                      class="data-details-info dd-info"
                      v-if="order.order.orderPaymentMethod == 4"
                      >{{ $t("orchard") }}</span
                    >
                  </div>
                  <div class="fake-class">
                    <span class="data-details-title"
                      >{{ $t("valid_code") }}:</span
                    >
                    <span class="data-details-info">{{
                      order.order.validationCode
                    }}</span>
                  </div>
                  <div class="fake-class">
                    <span class="data-details-title"
                      >{{ $t("order_val") }}:</span
                    >
                    <span
                      class="data-details-info os"
                      :class="
                        order.order.isValidated
                          ? 'os-outline-success'
                          : 'os-outline-danger'
                      "
                      >{{ order.order.isValidated }}</span
                    >
                  </div>
                  <div class="fake-class">
                    <span class="data-details-title"
                      >{{ $t("o_status") }}:</span
                    >
                    <span
                      class="data-details-info os"
                      :class="
                        order.order.orderStatus === 0
                          ? 'os-primary'
                          : order.order.orderStatus === 1
                          ? 'os-success'
                          : 'os-danger'
                      "
                    >
                      {{
                        order.order.orderStatus === 0
                          ? $t("pending")
                          : order.order.orderStatus === 1
                          ? $t("delivered")
                          : $t("cancelled")
                      }}
                    </span>
                    <!-- <span class="data-details-info badge badge-danger">Cancelled</span> -->
                  </div>
                  <div class="fake-class">
                    <span class="data-details-title"
                      >{{ $t("o_pstats") }}:</span
                    >
                    <span
                      class="data-details-info os"
                      :class="
                        order.order.orderPaymentStatus === 0
                          ? 'os-primary'
                          : order.order.orderPaymentStatus === 1
                          ? 'os-success'
                          : order.order.orderPaymentStatus === 2
                          ? 'os-info'
                          : 'os-danger'
                      "
                    >
                      {{
                        order.order.orderPaymentStatus === 0
                          ? "Pending"
                          : order.order.orderPaymentStatus === 1
                          ? "Approved"
                          : order.order.orderPaymentStatus === 2
                          ? "In progress"
                          : "Failed"
                      }}
                    </span>
                  </div>
                  <div class="fake-class" v-if="order.order.externalReference">
                    <span class="data-details-title"
                      >Customer reference number:</span
                    >
                    <span class="data-details-info os">{{
                      order.order.externalReference
                    }}</span>
                  </div>

                  <div class="fake-class mt-5">
                    <span
                      class="data-details-title font-weight-bold dd-total ucap"
                      >{{ $t("total") }}</span
                    >
                    <span
                      class="data-details-info badge badge-md btn-primary ucap total-amt"
                      >{{ this.$store.state.user.country.currency }}
                      {{ formatPrice(order.order.orderTotalPrice) }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-7">
          <div class="card content-area">
            <div class="card-innr pt-5 px-4">
              <h2 class="card-sub-name mb-4 font-weight-bold">Order Summary</h2>
              <div class="table-responsive">
                <table class="data-table table-striped">
                  <thead>
                    <tr class="data-item data-head">
                      <th class="data-col dt-user">{{ $t("s_n") }}</th>
                      <th class="data-col">{{ $t("product") }}</th>
                      <th class="data-col">{{ $t("unit") }}</th>
                      <th class="data-col">{{ $t("qty") }}</th>
                      <!-- <th class="data-col">Price</th> -->
                      <th class="data-col dt-status">{{ $t("total") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="data-item"
                      v-for="(ord, index) in order.orderDetails"
                      :key="ord.id"
                    >
                      <td class="data-col dt-user">
                        <span class="sub sub-s2">{{ ++index }}.</span>
                      </td>
                      <td class="data-col">
                        <span class="sub sub-s2">{{ ord.productName }}</span>
                      </td>
                      <td class="data-col">
                        <span class="sub sub-s2">{{
                          ord.productUnitName
                        }}</span>
                      </td>
                      <td class="data-col">
                        <span class="sub sub-s2">{{
                          ord.orderDetail.productQuantity
                        }}</span>
                      </td>
                      <!-- <td class="data-col">
                                                <span class="sub sub-s2">{{user.country.currency}} {{formatPrice(ord.orderDetail.productUnitPrice)}}</span>
                                            </td> -->
                      <td class="data-col">
                        <span class="sub sub-s2"
                          >{{ user.country.currency }}
                          {{
                            formatPrice(ord.orderDetail.productTotalPrice)
                          }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="subtotal d-flex justify-content-end py-3 mt-3">
                <div class="sub-con">
                  <span class="pr-5 ucap or-subt">{{ $t("subtotal") }}</span>
                  <span class="badge btn-primary ucap total-amt or-kil"
                    >{{ user.country.currency }}
                    {{ formatPrice(order.order.orderTotalPrice) }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-5 mt-0">
          <div
            class="c-footer mt-3 d-block w-100 d-md-flex justify-content-end"
          >
            <div class="down-btn" v-if="!order.order.isValidated">
              <button
                class="btn mt-2 btn-sm btn-success"
                @click="updateExternalReference()"
              >
                <em class="text-white ti ti-check"></em>
                <span>{{ $t("co_order") }}</span>
              </button>
            </div>
            <div class="down-btn">
              <button class="btn mt-2 btn-sm btn-danger" @click="cancelOrder()">
                <em class="text-white fa fa-times-circle"></em>
                <span>{{ $t("ca_order") }}</span>
              </button>
            </div>
            <div class="down-btn">
              <a
                class="btn mt-2 btn-sm btn-primary"
                href="javascript: window.print()"
              >
                <em class="text-white ti ti-printer"></em>
                <span>{{ $t("pr_order") }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="editMode" v-else>
        <div class="gaps-1-5x"></div>
        <div class="card content-area">
          <div class="card-innr pt-5 px-4">
            <div class="data-details">
              <h2 class="card-sub-title mb-4 font-weight-bold">
                {{ $t("add_new_prod") }}
              </h2>
              <form ref="submitForm" @submit.prevent="addProduct()">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <label>{{ $t("products") }}</label>
                      <v-select
                        v-model="newOrder.product"
                        :options="filterProducts"
                        label="productName"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <label>{{ $t("unit") }}</label>
                      <v-select
                        v-model="newOrder.prodUnitId"
                        :options="units"
                        :reduce="(unit) => unit.id"
                        label="productUnitName"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <label>{{ $t("quantity") }}</label>
                      <input
                        type="number"
                        v-model="newOrder.quantity"
                        value="0"
                        min="1"
                        step=".01"
                        class="form-control"
                        style="height: 42px"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <button
                      type="submit"
                      class="btn font-weight-bold"
                      style="background: #00738c"
                    >
                      {{ $t("add_prod") }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="gaps-3x"></div>
        <div class="card content-area">
          <div class="card-innr pt-5 px-4">
            <h2
              class="card-sub-name mb-4 font-weight-bold"
              style="font-size: 18px"
            >
              Order Summary
            </h2>
            <div class="table-responsive">
              <table class="data-table dt-init kyc-list table-striped">
                <thead>
                  <tr class="data-item data-head">
                    <th class="data-col dt-user">{{ $t("s_n") }}</th>
                    <th class="data-col">{{ $t("product") }}</th>
                    <th class="data-col">{{ $t("unit") }}</th>
                    <th class="data-col">{{ $t("qty") }}</th>
                    <th class="data-col">{{ $t("total") }}</th>
                    <th class="data-col">{{ $t("action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="data-item"
                    v-for="(ord, index) in editOrder.orderDetails"
                    :key="ord.id"
                  >
                    <td class="data-col dt-user">
                      <span class="sub sub-s2">{{ ++index }}</span>
                    </td>
                    <td class="data-col">
                      <span class="sub sub-s2">{{ ord.productName }}</span>
                    </td>
                    <td class="data-col">
                      <select
                        :class="ord.productId"
                        @change="updateProductUnitId($event, ord.productId)"
                        class="input-bordered"
                      >
                        <option
                          v-for="(unit, index) in units"
                          :key="index"
                          :value="unit.id"
                          :selected="
                            ord.productUnitName == unit.productUnitName
                              ? selected
                              : ''
                          "
                        >
                          {{ unit.productUnitName }}
                        </option>
                      </select>
                    </td>
                    <td class="data-col">
                      <!-- <span class="sub sub-s2">{{ord.productQuantity}}</span> -->
                      <div class="d-flex justify-content-center">
                        <input
                          type="number"
                          step=".01"
                          min="1"
                          @input="changeQty(ord.productId)"
                          :value="ord.productQuantity"
                          class="form-control prod-qty"
                          :id="ord.productId"
                        />
                      </div>
                    </td>
                    <td class="data-col">
                      <span class="sub sub-s2"
                        >{{ user.country.currency }}
                        {{ formatPrice(ord.productTotalPrice) }}</span
                      >
                    </td>
                    <td class="data-col">
                      <button
                        class="btn btn-trash"
                        @click="deleteOrder(ord.productId)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="subtotal d-block d-sm-flex justify-content-between align-items-center py-3"
              >
                <div class="sub-con">
                  <button class="btn btn-success" @click="updateOrder()">
                    <span v-if="!loading">{{ $t("up_order") }}</span>
                    <span v-else>{{ $t("loading") }}</span>
                  </button>
                </div>
                <div class="sub-con mt-3 mt-sm-0">
                  <span class="pr-5 text-uppercase">{{ $t("subtotal") }}:</span>
                  <button class="btn btn-primary">
                    <span
                      >{{ user.country.currency }}
                      {{ formatPrice(calculateTotal) }}</span
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="customerRef" tabindex="-1">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content py-0">
          <div
            class="modal-header d-flex justify-content-between align-items-center"
          >
            <div class="modal-title">Additional info</div>
          </div>
          <!-- <a href="#" class="modal-close" data-dismiss="modal" aria-label="Close"><em class="ti ti-close"></em></a> -->
          <div class="popup-body p-4">
            <div class="form">
              <form @submit.prevent="updateExternalReference()">
                <div class="momo-form-group">
                  <label for="">Customer reference number</label>
                  <input
                    type="text"
                    v-model="reference_no"
                    class="form-control"
                  />
                </div>
                <div class="momo-submit my-4">
                  <button
                    type="submit"
                    class="btn btn-primary w-100"
                    :disabled="loading"
                  >
                    <span v-if="!loading">Confirm Order</span>
                    <span v-else>Loading...</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>

    <div class="modal fade" id="editPaymentMethod" tabindex="-1">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content py-0">
          <div
            class="modal-header d-flex justify-content-between align-items-center"
          >
            <div class="modal-title">Select Payment Method</div>
          </div>
          <!-- <a href="#" class="modal-close" data-dismiss="modal" aria-label="Close"><em class="ti ti-close"></em></a> -->
          <div class="popup-body p-4">
            <div class="form">
              <div class="form-group d-flex flex-wrap">
                <div
                  v-for="(item, index) in user.country.paymentMethods"
                  :key="index"
                  class="mr-2"
                >
                  <div class="form-check form-control">
                    <!-- show Opay & Paystack payment option on staging environmemt -->
                    <!-- v-if="item != 5 && item != 6" -->
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="paymentMethod"
                      :id="'gridRadios' + index"
                      :value="item"
                    />
                    <label
                      class="form-check-label ml-2"
                      :for="'gridRadios' + index"
                    >
                      <span v-if="item == 0">Payment on delivery </span>
                      <span v-if="item == 1">Buy on credit </span>
                      <span v-if="item == 2">Pay with POS </span>
                      <span v-if="item == 3">Pay with transfer </span>
                      <span v-if="item == 4">Pay with orchard </span>
                      <span v-if="item == 5">Pay with Opay </span>
                      <span v-if="item == 6">Pay with Paystack </span>
                    </label>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-primary"
                @click.prevent="changePaymentMethod()"
                :disabled="loading"
              >
                {{ loading ? "Loading..." : "Submit" }}
              </button>
            </div>
          </div>
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import "vue-select/dist/vue-select.css";
  export default {
    computed: {
      ...mapState({
        user: (context) => context.user,
      }),
      calculateTotal() {
        let total = 0;
        this.editOrder.orderDetails.forEach((element) => {
          total += element.productTotalPrice;
        });
        return total.toFixed(2);
      },
    },
    data() {
      return {
        paymentMethod: "",
        reference_no: null,
        selected: true,
        order: {},
        ready: false,
        delivered: 0,
        pending: 0,
        cancelled: 0,
        viewMode: true,
        products: [],
        units: [],
        newOrder: {
          quantity: 1,
        },
        editOrder: {},
        filterProducts: [],
        loading: false,

        orchardResp: {},
      };
    },
    methods: {
      changePaymentMethod() {
        if (!this.paymentMethod && this.paymentMethod != 0) {
          window.ToasterAlert("error", "Payment method is requred");
          return;
        }

        if (this.paymentMethod == 0) {
          this.payOnDelivery();
        } else if (this.paymentMethod == 4) {
          this.payWithOrchard();
        }
      },
      payOnDelivery() {
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: `services/app/Orders/PayOnDelivery?orderId=${this.order.order.id}`,
            details: {},
          })
          .then((resp) => {
            this.loading = false;
            console.log(resp);
            window.ToasterAlert(
              "success",
              "Payment changed to pay on delivery"
            );
            window.location.reload();
          })
          .catch(() => {
            this.loading = false;
          });
      },
      payWithOrchard() {
        this.loading = true;
        var redirectURL = `${process.env.VUE_APP_BASE_URL}/order/${this.order.order.id}`;
        this.$store
          .dispatch("post", {
            endpoint: "services/app/Orchard/InitiatePaymentV2",
            details: { orderId: this.order.order.id, redirectURL: redirectURL },
          })
          .then((resp) => {
            console.log(resp);
            this.orchardResp = JSON.parse(resp.data.result.serviceResponse);
            console.log(this.orchardResp);
            if (this.orchardResp.resp_code == "000") {
              window.location.href = this.orchardResp.redirect_url;
            } else {
              window.ToasterAlert(
                "error",
                "Error initializing payment, please try again"
              );
            }
          });
      },
      checkOrchardPaymentStatus() {
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: `services/app/Orchard/CheckPaymentStatusForOrder?OrderId=${this.order.order.id}`,
            details: {},
          })
          .then(() => {
            this.loading = false;
            window.location.reload();
          });
      },
      updateOrder() {
        this.editOrder.orderTotalPrice = this.calculateTotal;
        var totalQty = 0;
        this.editOrder.orderDetails.forEach((element) => {
          totalQty += parseFloat(element.productQuantity);
        });
        this.editOrder.orderTotalQuantity = parseFloat(totalQty);
        this.editOrder.orderChannel = 1;
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: "services/app/Orders/CreateOrEdit",
            details: this.editOrder,
          })
          .then((resp) => {
            if (resp.data.success) {
              this.loading = false;
              this.getOrder();
              window.SweetAlert("success", "Order updated successfully.");
              this.viewMode = true;
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$store.dispatch("handleError", error);
          });
      },
      deleteOrder(id) {
        this.editOrder.orderDetails.forEach((item, index) => {
          if (item.productId == id) {
            this.editOrder.orderDetails.splice(index, 1);
          }
        });
        var sProd = this.products.filter(
          (product) => product.product.id == id
        )[0];
        this.filterProducts.push(sProd.product);
      },
      changeQty(id) {
        var num = parseFloat(document.getElementById(id).value);
        if (!isNaN(num)) {
          var sProd = this.products.filter(
            (product) => product.product.id == id
          )[0];
          this.editOrder.orderDetails.forEach((order) => {
            if (order.productId == id) {
              order.productQuantity = num;
              var baseUnitFactor = 0;
              this.units.forEach((item) => {
                if (sProd.product.productBaseUnitId == item.id) {
                  baseUnitFactor = item.conversionFactor;
                }
              });
              this.units.forEach((item) => {
                if (order.productUnitId == item.id) {
                  var conversionFactor = item.conversionFactor;
                  order.convertedPrice =
                    (parseFloat(baseUnitFactor) /
                      parseFloat(conversionFactor)) *
                    sProd.product.unitPrice;
                  // console.log(order.convertedPrice)
                }
              });
              order.productTotalPrice =
                order.productQuantity * order.convertedPrice;
            }
          });
        }
      },
      addProduct() {
        if (this.newOrder.quantity < 1) {
          window.ToasterAlert("error", "Invalid quantity");
          return false;
        }
        if (!this.newOrder.prodUnitId) {
          window.ToasterAlert("error", "Unit is required");
          return false;
        }
        if (!this.newOrder.product) {
          window.ToasterAlert("error", "Product is required");
          return false;
        }
        var newProd = {};
        newProd.productName = this.newOrder.product.productName;
        newProd.productQuantity = this.newOrder.quantity;
        newProd.productId = this.newOrder.product.id;
        newProd.productUnitId = this.newOrder.prodUnitId;
        var baseUnitFactor = 0;
        this.units.forEach((item) => {
          if (this.newOrder.product.productBaseUnitId == item.id) {
            baseUnitFactor = item.conversionFactor;
          }
        });
        this.units.forEach((item) => {
          if (newProd.productUnitId == item.id) {
            newProd.productUnitName = item.productUnitName;
            var conversionFactor = item.conversionFactor;
            newProd.convertedPrice =
              (parseFloat(baseUnitFactor) / parseFloat(conversionFactor)) *
              this.newOrder.product.unitPrice;
          }
        });
        newProd.productTotalPrice =
          newProd.productQuantity * newProd.convertedPrice;
        this.editOrder.orderDetails.push(newProd);
        this.removeProductFromProducts();
        this.newOrder.product = "";
        this.newOrder.prodUnitId = "";
        this.newOrder.quantity = 1;
      },
      updateProductUnitId(event, productId) {
        // console.log(event.target.value)
        // console.log(productId)
        var num = parseFloat(document.getElementById(productId).value);
        var sProd = this.products.filter(
          (product) => product.product.id == productId
        )[0];
        this.editOrder.orderDetails.forEach((order) => {
          if (order.productId == productId) {
            order.productUnitId = event.target.value;
            order.productUnitName = this.units.filter(
              (unit) => unit.id == event.target.value
            )[0].productUnitName;
            order.productQuantity = num;
            var baseUnitFactor = 0;
            this.units.forEach((item) => {
              if (sProd.product.productBaseUnitId == item.id) {
                baseUnitFactor = item.conversionFactor;
              }
            });
            this.units.forEach((item) => {
              if (order.productUnitId == item.id) {
                var conversionFactor = item.conversionFactor;
                order.convertedPrice =
                  (parseFloat(baseUnitFactor) / parseFloat(conversionFactor)) *
                  sProd.product.unitPrice;
              }
            });
            order.productTotalPrice =
              order.productQuantity * order.convertedPrice;
          }
        });
      },
      toggleEditMode() {
        this.viewMode = !this.viewMode;
      },
      formatPrice(value) {
        return new Intl.NumberFormat("en-NG", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
      },
      deliveryOrder() {
        this.$store
          .dispatch("post", {
            endpoint:
              "services/app/Orders/DeliverOrder?orderId=" + this.order.order.id,
            details: { tenantId: this.user.country.tenantId },
          })
          .then((resp) => {
            // console.log(resp)
            if (resp.data.success) {
              this.getOrder();
              window.SweetAlert("success", "Order delivered successfully");
            } else {
              window.SweetAlert("error", "Error delivering order");
            }
          });
      },
      async getOrder() {
        await this.$store
          .dispatch(
            "get",
            "services/app/Orders/GetOrderForView?id=" + this.$route.params.id
          )
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.ready = true;
              this.order = resp.data.result;
              this.editOrder = { ...this.order.order, orderDetails: [] };
            }
          });
      },
      updateExternalReference() {
        if (this.user.country.tenantId == 9 && this.reference_no == null) {
          window.ToasterAlert("error", "Customer reference number is required");
          window.$("#customerRef").modal("show");
          return false;
        }
        this.loading = true;
        this.$store
          .dispatch("put", {
            endpoint:
              "services/app/Orders/UpdateExternalReference?orderId=" +
              this.order.order.id +
              "&ExternalReference=" +
              this.reference_no,
            details: {},
          })
          .then((resp) => {
            window.$("#customerRef").modal("hide");
            this.loading = false;
            console.log(resp.data);
            this.confirmOrder();
          });
      },
      confirmOrder() {
        this.$store
          .dispatch("post", {
            endpoint:
              "services/app/Orders/ConfirmOrder?orderId=" + this.order.order.id,
            details: { tenantId: this.user.country.tenantId },
          })
          .then((resp) => {
            // console.log(resp)
            this.reference_no = null;
            if (resp.data.success) {
              this.getOrder();
              window.SweetAlert("success", "Order confirm successfully");
            } else {
              window.SweetAlert("error", "Error confirming order");
            }
          });
      },
      cancelOrder() {
        this.$store
          .dispatch("post", {
            endpoint:
              "services/app/Orders/CancelOrder?orderId=" + this.order.order.id,
            details: { tenantId: this.user.country.tenantId },
          })
          .then((resp) => {
            if (resp.data.success) {
              this.getOrder();
              window.SweetAlert("success", "Order cancelled successfully");
            } else {
              window.SweetAlert("error", "Error cancelling order");
            }
          });
      },
      getProducts() {
        this.$store
          .dispatch(
            "getProduct",
            "services/app/Products/GetAll?MaxResultCount=1000"
          )
          .then((resp) => {
            // console.log(resp)
            if (resp.data.success) {
              this.products = resp.data.result.items;
              this.filterProducts = this.products.map(
                (product) => product.product
              );

              this.order.orderDetails.forEach((item) => {
                this.products.forEach((prod) => {
                  if (prod.product.id == item.orderDetail.productId) {
                    var saved = {};
                    saved.productUnitPrice = prod.product.unitPrice;
                    saved.productTotalPrice =
                      item.orderDetail.productTotalPrice;
                    saved.productQuantity = item.orderDetail.productQuantity;
                    saved.productId = item.orderDetail.productId;
                    saved.productUnitId = item.orderDetail.productUnitId;
                    saved.id = item.orderDetail.id;
                    saved.orderId = item.orderDetail.orderId;
                    saved.productName = item.productName;
                    saved.productUnitName = item.productUnitName;
                    this.editOrder.orderDetails.push(saved);
                  }
                });
              });
              this.removeProductFromProducts();
            }
          });
      },
      removeProductFromProducts() {
        this.editOrder.orderDetails.forEach((item) => {
          this.filterProducts.forEach((prod) => {
            if (prod.id == item.productId) {
              this.filterProducts.splice(
                this.filterProducts.findIndex((p) => p.id == item.productId),
                1
              );
            }
          });
        });
      },
      getUnits() {
        this.$store
          .dispatch("getWithTalent", "services/app/ProductUnits/GetAll")
          .then((resp) => {
            if (resp.data.success) {
              // this.units = resp.data.result.items
              this.units = resp.data.result.items.map(
                (unit) => unit.productUnit
              );
            }
          });
      },
    },
    async created() {
      await this.getOrder();
      this.getProducts();
      this.getUnits();
    },
  };
</script>

<style>
  .vs__dropdown-toggle {
    height: 42px;
  }
</style>
<style scoped>
  #editPaymentMethod .form-check-input {
    margin-top: 6px;
    margin-left: -13px;
  }
  .btn-trash {
    background: rgba(204, 20, 19, 0.1);
    color: #cc1413;
    border: none;
    padding: 3px 11px;
  }
  .edit-icon span {
    font-weight: bold;
  }
  .prod-qty,
  .input-bordered {
    width: 100px;
  }
  .editMode .data-head .data-col {
    width: fit-content;
  }
  .btn {
    min-width: 0;
  }
  .sub-s2 {
    font-size: 12px;
    font-family: plus_jakarta_displayregular;
    color: #061435;
  }
  .btn-success {
    background: #03a500 !important;
  }
  .down-btn .btn-sm {
    padding: 10px 18px;
    border-radius: 3px;
    font-family: plus_jakarta_displayregular;
    font-weight: bold;
  }
  .down-btn em {
    font-size: 20px;
  }
  .os-outline-success {
    color: #03a500;
    border: 1px solid #03a500;
  }
  .os-outline-danger {
    color: #ff0000;
    border: 1px solid #ff0000;
  }
  .btn-danger {
    background: #ff0000;
  }
  .or-kil {
    font-weight: normal !important;
    font-size: 15px !important;
    font-family: plus_jakarta_displayregular;
  }
  .or-subt {
    color: rgba(17, 42, 99, 0.8);
  }
  .data-table:not(.dt-init) .data-item:last-child .data-col {
    padding-bottom: 15px;
  }
  .down-btn {
    margin-right: 10px;
  }
  .total-amt {
    font-size: 14px;
    padding: 14px 30px;
    border-radius: 4px;
    font-weight: bold;
  }
  .dd-total {
    font-size: 20px;
  }
  .dd-info {
    font-weight: bold;
    color: rgba(17, 42, 99, 0.8);
  }
  .os-primary {
    color: #ff6b00;
    background: rgba(255, 107, 0, 0.15);
  }
  .os-danger {
    color: #ff0000;
    background: rgba(255, 0, 0, 0.15);
  }
  .os-info {
    background: #061435;
    color: #fff;
  }
  .os-success {
    color: #03a500;
    background: #d1eed1;
  }
  .os {
    padding: 10px 30px;
    border-radius: 3px;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 14px;
  }
  .card {
    border: 1px solid rgba(17, 42, 99, 0.1);
  }
  .card-sub-name {
    color: rgba(17, 42, 99, 0.8);
    font-size: 24px;
    letter-spacing: 0;
  }
  .data-details {
    padding: 0;
    border: none;
  }
  .fake-class {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    text-transform: capitalize;
  }
  .card-title {
    color: #112a63e5;
    font-size: 24px;
    font-weight: bold;
  }
  .c-footer {
    text-align: center;
  }
  .subtotal span {
    font-size: 16px;
    font-weight: bold;
  }
  thead .data-item {
    border-bottom: 1px solid #d2dde9;
  }
  thead th {
    padding-bottom: 12px !important;
  }
  .data-head .data-col {
    color: #495463;
    width: 20%;
    font-weight: 600;
  }
  .data-col {
    padding: 15px 10px 15px 0;
    text-align: center;
  }
  .data-details-info.badge {
    width: fit-content !important;
  }
  .card-sub-title {
    font-size: 16px;
    line-height: 1px;
    color: rgba(17, 42, 99, 0.9);
    font-weight: bold;
    font-family: "plus_jakarta_displayregular";
  }
  .data-details-title {
    margin-top: 7px;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17, 42, 99, 0.8);
    font-family: "plus_jakarta_displayregular";
  }
  label {
    color: rgba(17, 42, 99, 0.9);
    font-size: 14px;
    font-family: plus_jakarta_displayregular;
  }
</style>
