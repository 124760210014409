<template>
  <div class="page-ath-wrap d-flex justify-content-center align-items-center">
    <div class="page-ath-content">
      <div class="page-ath-header d-flex align-items-center flex-column">
        <h3>{{ $t("select_store") }}</h3>
        <p class="text-center">{{ $t("txt1") }}</p>
        <!-- <a href="/" class="page-ath-logo"><img src="../assets/images/small-logo.png" alt="logo"></a> -->
      </div>
      <div class="page-ath-form">
        <form @submit.prevent="switchOutlet()">
          <div class="form-group mt-3">
            <label for="name">{{ $t("s_name") }}</label>
            <select
              class="form-control"
              id="name"
              name="outlets"
              :size="user.outlets.length"
              v-model="outlet"
            >
              <option
                v-for="(outlet, index) in user.outlets"
                :key="index"
                :value="outlet"
                >{{ outlet.customer.outletName }}</option
              >
            </select>
          </div>
          <div class="d-flex justify-content-center mt-5">
            <button class="btn btn-primary" type="submit">
              <span v-if="!loading">{{ $t("proceed") }}</span>
              <span v-else>{{ $t("loading") }}</span>
            </button>
          </div>
        </form>
      </div>
      <div class="text-center mb-3">
        <language />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      user: (context) => context.user,
    }),
  },
  data() {
    return {
      loading: false,
      outlet: {},
    };
  },
  methods: {
    switchOutlet() {
      this.loading = true;
      this.$store.commit("setActiveOutlet", this.outlet);
      window.location.href = "/store";
    },
  },
  created() {
    this.$store.commit("getUser");
    this.outlet = this.user.outlets[0];
  },
};
</script>

<style scoped>
.page-ath-form {
  width: 498px;
}
.page-ath-content {
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
}
label {
  line-height: 19px !important;
}
.btn {
  min-width: 219px;
}
.page-ath-header h3 {
  font-weight: bold;
  color: #0b1e48;
}
select {
  height: auto !important;
}
@media (min-width: 768px) {
  .page-ath-content {
    width: 55%;
  }
}
</style>
