<template>
  <div class="page-content">
    <div class="container" v-if="cart.bundleOffers.length > 0">
      <div class="row">
        <div class="col-sm-8">
          <div class="card content-area">
            <div class="card-innr pl-0 pr-2">
              <div class="card-head">
                <h4 class="card-title">Order Summary</h4>
              </div>
              <div class="table-responsive">
                <table class="data-table dt-init kyc-list table-striped">
                  <thead>
                    <tr class="data-item data-head">
                      <th class="table-col"></th>
                      <th class="table-col">{{ $t("product") }}</th>
                      <th class="table-col">{{ $t("qty") }}</th>
                      <th class="table-col">{{ $t("total") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="data-item"
                      v-for="item in cart.bundleOffers"
                      :key="item.id"
                    >
                      <td class="table-col">
                        <img :src="item.productBundle.imageURL" alt="" />
                      </td>
                      <td class="table-col">
                        <span class="sub sub-s2">{{
                          item.productBundle.displayName
                        }}</span>
                      </td>
                      <td class="table-col">
                        <span class="sub sub-s2">{{ item.quantity }}</span>
                      </td>
                      <td class="table-col">
                        <span class="sub sub-s2"
                          >{{ user.country.currency
                          }}{{
                            formatPrice(
                              item.quantity * item.productBundle.unitPrice
                            )
                          }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="subtotal d-flex justify-content-end my-4 pt-3">
                <div class="sub-con">
                  <span class="d-block fee-left">{{ $t("subtotal") }}:</span>
                  <span class="d-block fee-left fee-down fee-border"
                    >Delivery fee:</span
                  >
                  <span class="d-block total-left fee-left mt-3"
                    >{{ $t("total") }}:</span
                  >
                </div>

                <div class="sub-con text-right">
                  <span class="d-block"
                    >{{ user.country.currency }} {{ formatPrice(total) }}</span
                  >
                  <span class="d-block fee-down fee-border">Free</span>
                  <span class="d-block total-rgt mt-3"
                    >{{ user.country.currency }} {{ formatPrice(total) }}</span
                  >
                </div>
              </div>

              <div class="review mt-5 d-flex justify-content-between mb-5 pl-2">
                <router-link to="store" class="btn btn-default">{{
                  $t("c_shop")
                }}</router-link>
                <button
                  class="btn btn-primary font-weight-bold"
                  @click="submitOrder()"
                >
                  <span v-if="!loading">PROCEED TO CHECKOUT</span>
                  <span v-else>{{ $t("sub") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form mt-4">
            <form>
              <div class="form-body">
                <h5>1. Contact Information</h5>
                <div class="form-group">
                  <label for="">FULL NAME</label>
                  <input
                    disabled
                    :value="user.firstName"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="">PHONE</label>
                  <input
                    disabled
                    :value="user.phoneNumber"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-body">
                <h5>2. Delivery Address</h5>
                <div class="form-group row">
                  <div class="col">
                    <label for="">ADDRESS</label>
                    <input
                      disabled
                      :value="user.outletAddress"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="form-body">
                <h5>3.Payment Options</h5>
                <div class="form-group">
                  <div class="form-check form-control">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="paymentMethod"
                      id="gridRadios1"
                      value="payOnDelivery"
                      checked
                    />
                    <label class="form-check-label" for="gridRadios1">
                      Payment on delivery
                    </label>
                  </div>
                  <div class="form-check form-control">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="paymentMethod"
                      id="gridRadios2"
                      value="momo"
                    />
                    <label class="form-check-label" for="gridRadios2">
                      Pay with mobile money
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-5" v-else>
      <p class="alert alert-primary">{{ $t("noitem") }}</p>
      <!-- <button class="btn btn-primary" data-toggle="modal" data-target="#momoModalPending">Momo</button> -->
      <router-link to="store" class="btn btn-primary">
        <i class="fa fa-chevron-left mr-2"></i> {{ $t("go_back") }}</router-link
      >
    </div>
    <!-- <button class="subtn" id="toggleSuccess" @click="test()">Text</button> -->
    <div class="modal fade" id="succModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content py-0">
          <!-- <a href="#" class="modal-close" data-dismiss="modal" aria-label="Close"><em class="ti ti-close"></em></a> -->
          <div class="popup-body p-0">
            <div class="first-half">
              <div class="head-img">
                <img src="@/assets/images/1.png" class="w-100" />
              </div>
              <div class="mark d-flex justify-content-center bg-white">
                <div class="mark-img" style="width: 50px">
                  <img class="w-100" src="@/assets/images/2.png" />
                </div>
              </div>
              <h5 class="text-center text-succ">Order Successful</h5>
              <p class="text-center cust-ref mb-5">
                Customer reference number:
                <span class="font-weight-bold">{{ successMsg.order_id }} </span>
              </p>
            </div>
            <div class="second-half px-5">
              <div class="sh-content pt-5 pb-2">
                <div class="sh-item">
                  <p>Customer's name:</p>
                  <h6>{{ user.firstName }} {{ user.lastName }}</h6>
                </div>
                <div class="sh-item">
                  <p>Order number:</p>
                  <h6>{{ successMsg.order_number }}</h6>
                </div>
                <!-- <div class="sh-item">
                                    <p>Order Quantity: </p>
                                    <h6>2 </h6>
                                </div> -->
                <div class="sh-item">
                  <p>Total Amount:</p>
                  <h6>{{ user.country.currency }} {{ formatPrice(total) }}</h6>
                </div>
                <!-- <div class="sh-item">
                                    <p>Time of Transaction: </p>
                                    <h6> </h6>
                                </div> -->
                <div class="sh-item">
                  <p>Transaction Status:</p>
                  <h6 class="text-success">Successful</h6>
                </div>
              </div>
              <div class="sh-buttom text-center">
                <a class="btn btn-primary" href="/dashboard"
                  >Proceed to Dashboard</a
                >
              </div>
            </div>
          </div>
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>
    <!-- <div v-if="checkOut.status"> -->
    <momoModal :momo="checkOut" />
    <!-- </div> -->
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import momoModal from "./layout/momo-modal.vue";
  export default {
    computed: {
      ...mapState({
        cart: (context) => context.cart,
        user: (context) => context.user,
      }),
    },
    components: { momoModal },
    data() {
      return {
        loading: false,
        ref_no: "",
        orderId: "",
        total: 0,
        successMsg: {
          order_number: "",
          order_id: "",
        },
        paymentMethod: "payOnDelivery",
        checkOut: {
          status: false,
        },
      };
    },
    methods: {
      calculateTotal() {
        let sum = 0;
        this.cart.bundleOffers.forEach((element) => {
          let price = element.quantity * element.productBundle.unitPrice;
          sum += price;
        });
        this.total = sum.toFixed(2);
      },
      formatPrice(value) {
        var hold = parseFloat(value).toFixed(2);
        return new Intl.NumberFormat("en-NG", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(hold);
      },
      confirmOrder(msg) {
        this.$store
          .dispatch("post", {
            endpoint:
              "services/app/Orders/ConfirmOrder?orderId=" + this.orderId,
            details: { tenantId: this.user.country.tenantId },
          })
          .then((resp) => {
            if (resp.data.success) {
              window.SweetAlert("success", msg);
            } else {
              window.SweetAlert("error", "Error placing and confirming order");
            }
          });
      },
      submitOrder() {
        var orders = [];
        this.cart.bundleOffers.forEach((element) => {
          var item = {};
          item.totalPrice = element.quantity * element.productBundle.unitPrice;
          item.quantity = element.quantity;
          item.productBundleId = element.productBundle.id;
          item.unitPrice = element.productBundle.unitPrice;
          orders.push(item);
        });
        var details = {
          customerId: this.user.id,
          totalPrice: this.total,
          dateTime: new Date(),
          bundleOrderDetails: orders,
        };
        // console.log(details)
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: "services/app/BundleOrders/Create",
            details: details,
          })
          .then((resp) => {
            if (resp.data.success) {
              this.loading = false;
              this.orderId = resp.data.result.orderId;
              this.successMsg.order_number = resp.data.result.orderNumber;
              this.successMsg.order_id = resp.data.result.orderID;
              this.checkOut = details;
              this.checkOut.status = true;
              this.checkOut.reference_id = this.orderId;
              console.log(this.checkOut);
              if (this.paymentMethod == "momo") {
                window.$("#momoModal").modal("show");
              } else {
                window.$("#succModal").modal("show");
                this.cart.bundleOffers = [];
              }
              // this.confirmOrder('Order placed successfully. Order Number: '+resp.data.result.orderNumber+' Validation code: '+resp.data.result.validationCode)
              // window.SweetAlert('success', 'Order placed successfully. Order Number: '+resp.data.result.orderNumber+' Validation code: '+resp.data.result.validationCode)

              //dont clear cart until payment is done
              // this.cart.bundleOffers=[]
            }
          });
      },
    },
    created() {
      // window.SweetAlert('success', "suuceess")
      this.calculateTotal();
    },
  };
</script>

<style scoped>
  #succModal .modal-dialog {
    max-width: 415px !important;
    min-width: 0;
  }
  #succModal .modal-content {
    background: transparent;
    box-shadow: none;
  }
  .sh-item {
    display: flex;
    justify-content: space-between;
  }
  .sh-item {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: rgba(6, 20, 53, 0.8);
    margin-top: 10px;
  }
  .sh-item h6 {
    font-weight: bold;
  }
  .text-succ {
    margin-top: 20px;
    color: rgba(0, 133, 13, 0.8);
    font-weight: bold;
    font-size: 20px;
  }
  .cust-ref,
  .cust-ref span {
    color: rgba(6, 20, 53, 0.8);
    font-size: 14px;
  }
  .first-half {
    clip-path: polygon(
      50% 0%,
      100% 0,
      100% 87%,
      90% 100%,
      10% 100%,
      0 87%,
      0 0
    );
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 46px;
    border-bottom-left-radius: 46px;
    border-bottom: 0.5px dashed #043d69;
    background: #fff;
  }
  .head-img img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .mark {
    margin-top: -30px;
  }
  .second-half {
    clip-path: polygon(50% 0%, 90% 0, 100% 10%, 100% 100%, 0 99%, 0 10%, 10% 0);
    background: #fff;
    padding-bottom: 34px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }
  .card-title {
    color: rgba(17, 42, 99, 0.8);
    font-size: 18px;
  }
  .form-check {
    padding-left: 34px;
    margin-top: 15px;
  }
  .form-body label {
    font-size: 12px;
    text-transform: uppercase;
  }
  .form-body {
    color: #061435;
    margin-top: 30px;
  }
  .form-body h5 {
    font-weight: 500;
    font-size: 16px;
    font-family: plus_jakarta_textregular;
  }
  .btn-default {
    color: #061435;
    border: 1px solid #061435;
    border-radius: 5px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .total-left {
    margin-right: 10em;
  }
  .fee-border {
    border-bottom: 1px solid rgba(6, 20, 53, 0.2);
  }
  .fee-left {
    font-weight: 400 !important;
    font-family: plus_jakarta_textregular;
  }
  .fee-down {
    margin-top: 20px;
    padding-bottom: 15px;
  }
  .data-item .sub-s2 {
    font-size: 12px;
    font-family: plus_jakarta_displayregular;
  }
  .ref {
    margin-bottom: 0;
  }
  .ref-input {
    min-width: 20em;
  }
  .card {
    border-radius: 0;
  }

  .btn {
    min-width: auto;
  }
  .data-item img {
    width: 25%;
  }
  .table-col {
    padding: 4px 10px 4px 0;
    width: 25%;
  }
  thead .data-item {
    border-bottom: 1px solid #d2dde9;
  }
  thead th {
    padding-bottom: 12px !important;
  }
  .data-head .table-col {
    color: #061435;
  }
  .c-footer {
    text-align: center;
  }
  .subtotal {
    border-top: 1px solid #e6effb;
    /* flex-direction: column; */
  }
  .subtotal span {
    font-size: 17px;
    font-weight: bold;
  }
  @media (max-width: 576px) {
    .data-item img {
      width: 80% !important;
    }
  }
  @media (max-width: 340px) {
    .btn {
      padding: 2px 11px;
      font-size: 12px;
    }
  }
</style>
